import * as React from "react"
import { useCallback, useState } from "react"
import { saveAs } from "file-saver"
import { IconButton } from "@mui/material"
import EventIcon from "@mui/icons-material/Event"
import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import { axiosInstance } from "../../../shared/utilities/request_utility"

interface IProps {
  wa: IWorkAssignment
}

/**
 * This button allows the download of a calendar ics file.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The calendar button.
 */
const CalendarDownloadButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const [loading, setLoading] = useState(false)

  const handleDownload = useCallback(async () => {
    setLoading(true)
    const { wa } = props
    const url = `/work_assignments/${wa.id}/visit_ical/`

    const response = await axiosInstance.get(url, { responseType: "blob" })
    saveAs(response.data, `rli-work-assignment-visit-${wa.id}.ics`)
    setLoading(false)
  }, [props])

  return (
    <>
      <IconButton aria-label="add to calendar" onClick={handleDownload} disabled={loading}>
        <EventIcon />
      </IconButton>
    </>
  )
}

export default CalendarDownloadButton

import * as React from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { locationToString } from "../../../shared/models/ILocation"
import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"

interface IProps {
  disabled: boolean
  onAcceptDeclineWa: (action?: string) => (comment?: string) => void
  wa: IWorkAssignment
}

/**
 * These are the accept / decline buttons for a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the buttons.
 */
const AcceptDeclineButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { disabled, onAcceptDeclineWa, wa } = props
  const [open, setOpen] = useState<boolean>(false)
  const [comment, setComment] = useState<string>("")

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleAccept = useCallback(() => {
    onAcceptDeclineWa()()
  }, [])

  const handleOpenDecline = useCallback(() => {
    setOpen(true)
  }, [])

  const handleSave = useCallback(() => {
    onAcceptDeclineWa("decline")(comment)
    setOpen(false)
    setComment("")
  }, [comment])

  const emailLink = useMemo(() => {
    if (wa.customer?.name !== undefined && wa.account?.name !== undefined) {
      const subject = `Consultant Support for ${wa.identifier}`
      const body = `Hi RiskLogic,
\n\nState your question...\n\n
======================================================
ID:\t\t\t${wa.identifier}
Customer:\t${wa.customer.name}
Account:\t\t${wa.account.name}
Location:\t\t${locationToString(wa.location)}
Due Date:\t${wa.consultant_due_date}
======================================================\n`
      return `mailto:requests@risklogic.com?subject=${subject}&body=${encodeURIComponent(body)}`
    }
  }, [wa])

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button onClick={handleAccept} color="success" variant="contained" fullWidth size="small" disabled={disabled}>
            Accept
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleOpenDecline} color="error" variant="contained" size="small" fullWidth disabled={disabled}>
            Decline
          </Button>
        </Grid>
        <Grid item>
          <Button component={Link} href={emailLink} variant="outlined" size="small">
            Contact RLI
          </Button>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Decline Inspection</DialogTitle>
        <DialogContent>
          <Box>
            <p>Are you sure you want to decline this work assignment?</p>
            <TextField fullWidth multiline minRows={3} label="Comment" value={comment} onChange={e => setComment(e.target.value)} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSave} color="error" variant="contained">
                Decline
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AcceptDeclineButton

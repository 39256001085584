import * as React from "react"
import { useCallback } from "react"
import WorkflowPage from "../../shared/components/WorkflowPage"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import { IWorkAssignment, WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT, workAssignmentLocation } from "../../shared/models/IWorkAssignment"
import { navigate } from "gatsby"
import { TECH_REVIEWS_VIEW_URL } from "../../config/urls"
import ErrorMessage from "../../shared/components/ErrorMessage"
import ListLoading from "../../shared/components/ListLoading"
import FormatDate from "../../shared/components/format/FormatDate"
import TableOrdering from "../../shared/components/TableOrdering"
import TableLoading from "../../shared/components/TableLoading"
import TableRowSelect from "../../shared/components/TableRowSelect"
import TechReviewAssignmentStatus from "./components/TechReviewAssignmentStatus"
import TablePaging from "../../shared/components/TablePaging"
import { RestRepository } from "../../shared/repositories/RestRepository"

const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT)

const columnCount = 8

/**
 * A page to list all expense reports.
 *
 * @returns {React.FC} the expense reports list page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))
  const props: IUseApiPagedResultsProps<IWorkAssignment> = { apiFunction: repository.findAll }

  const {
    data: workAssignments,
    count,
    page,
    error,
    loading,
    limit,
    ordering,
    handlePaging,
    handleOrdering,
  } = useApiPaged<IWorkAssignment>(props)

  const handleSelected = useCallback(
    (workAssignment: IWorkAssignment) => async () => {
      await navigate(`${TECH_REVIEWS_VIEW_URL}/${workAssignment.id}`)
    },
    []
  )

  return (
    <WorkflowPage>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        {isSmall ? (
          <List>
            <ListLoading loading={loading} rows={limit} />
            {!loading &&
              workAssignments?.results.map(wa => (
                <React.Fragment key={wa.id}>
                  <ListItemButton onClick={handleSelected(wa)} sx={{ mt: 1, mb: 1 }}>
                    <ListItemText
                      primary={
                        <Typography sx={{ display: "inline" }} variant="h6">
                          {workAssignmentLocation(wa)}
                        </Typography>
                      }
                      secondary={
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {wa.customer?.name} | {wa.account?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs sx={{ textAlign: "center" }}>
                            {wa.inspection_type_name}
                          </Grid>
                          <Grid item>
                            <FormatDate value={wa.tech_review_due_date} />
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
          </List>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <TableOrdering ordering={ordering} field="priority" title="Priority" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="customer__name" title="Customer" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="account__name" title="Account" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="location__name" title="Location" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>Reviewer</TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="inspection_type" title="Inspection Type" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    <TableOrdering ordering={ordering} field="tech_review_due_date" title="Due Date" onOrdering={handleOrdering} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading loading={loading} columns={columnCount} rows={limit} />
                {!loading &&
                  workAssignments?.results.map(wa => {
                    return (
                      <TableRowSelect key={wa.id} item={wa} onSelected={handleSelected(wa)}>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TechReviewAssignmentStatus workAssignment={wa} />
                          {wa.tech_review_number > 1 && <Box>Tech Review #{wa.tech_review_number}</Box>}
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>{wa.priority_name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{wa.customer?.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{wa.account?.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignmentLocation(wa)}</TableCell>
                        <TableCell>{wa.tech_reviewer_consultant_name}</TableCell>
                        <TableCell>{wa.inspection_type_name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <FormatDate value={wa.tech_review_due_date} />
                        </TableCell>
                      </TableRowSelect>
                    )
                  })}

                {!loading && workAssignments !== undefined && workAssignments.results.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={columnCount}>No work assignments found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <TablePaging
        count={count}
        size={isSmall ? "large" : "medium"}
        total={workAssignments?.count}
        page={page !== undefined ? page : 1}
        handlePaging={handlePaging}
      />
    </WorkflowPage>
  )
}

export default IndexPage

import * as React from "react"
import { ILocation, locationToString } from "../models/ILocation"
import { Box, Chip, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import GoogleMapsGeoLink from "./GoogleMapsGeoLink"

interface IProps {
  location: ILocation
}

/**
 * Displays the work assignment location information.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the work assignment location
 */
const WorkAssignmentLocation: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { location } = props
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  return (
    <Box>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 1, mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h5">Location</Typography>
        </Grid>
        <Grid item xs={12} sm>
          <Typography>{location.name}</Typography>
        </Grid>
        <Grid item xs={isSmall && 12}>
          <Grid container sx={{ textAlign: "center" }} spacing={2}>
            <Grid item xs>
              <Chip
                size="small"
                label={location.specialty?.name !== undefined ? location.specialty?.name : "Location Specialty Not Set"}
                sx={{ ml: 2 }}
                color="secondary"
              />
            </Grid>
            <Grid item xs>
              <Typography sx={{ color: "text.secondary", whiteSpace: "nowrap" }}>
                {location.area?.toLocaleString(undefined, { maximumFractionDigits: 0 })} ft<sup>2</sup>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" sx={{ mb: 1 }}>
        <Grid item xs={12} sm>
          <Typography>{locationToString(location)}</Typography>
        </Grid>
        <Grid item xs={12} sm sx={{ textAlign: "right" }}>
          <GoogleMapsGeoLink latitude={location.latitude} longitude={location.longitude} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkAssignmentLocation

import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"
import * as React from "react"

interface IProps {
  workAssignment: IWorkAssignment
}

/**
 * Displays the tech review status.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} The tech review status.
 */
const TechReviewAssignmentStatus: React.FunctionComponent<IProps> = (props: IProps) => {
  const { workAssignment } = props
  if (workAssignment.tech_review_accept !== null && workAssignment.tech_review_sign_off !== null) {
    return <>Complete</>
  }
  if (workAssignment.tech_review_accept !== null && workAssignment.tech_review_sign_off === null) {
    return <>In Progress</>
  }
  if (workAssignment.tech_review_decline !== null) {
    return <>Unavailable</>
  }
  return <>Awaiting Response</>
}

export default TechReviewAssignmentStatus

import { IWorkAssignment, workAssignmentConsultantHasResponded } from "../models/IWorkAssignment"
import useAuth from "../hooks/useAuth"
import * as React from "react"
import { Box } from "@mui/material"

interface IProps {
  wa: IWorkAssignment | null
}

/**
 * Displays the current status for a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the assignment status.
 */
const AssignmentStatus: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { currentUser } = useAuth()
  const { wa } = props
  const [hasResponded, waConsultant] = workAssignmentConsultantHasResponded(wa, currentUser?.user)
  if (hasResponded) {
    if (
      waConsultant !== null &&
      wa !== null &&
      waConsultant.accepted &&
      wa.progress_consultant_pre_survey_received === null &&
      wa.inspection_type !== "PR"
    ) {
      return <>Awaiting Pre-Survey</>
    }
    if (waConsultant !== null && wa !== null && waConsultant.accepted && wa.visit_date === null && wa.inspection_type !== "PR") {
      return <>Awaiting Visit</>
    }
    if (waConsultant !== null && wa !== null && waConsultant.accepted && wa.progress_consultant_reports_submitted === null) {
      return <>Awaiting Reports</>
    }
    if (waConsultant !== null && wa !== null && waConsultant.accepted && wa.progress_consultant_reports_submitted !== null) {
      return <>Complete</>
    }
    if (waConsultant?.declined === true) {
      return <>Unavailable</>
    }
  }
  return <React.Fragment>
    <Box sx={theme => ({color: theme.palette.warning.dark})}>Awaiting Response</Box>
  </React.Fragment>
}

export default AssignmentStatus

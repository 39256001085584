import { IConsultant } from "./IConsultant"


export const PROFILES_ENDPOINT = "profiles"

export interface IAdminHours {
  year: number,
  total_admin_hours: number,
  total_pto: number
}

export interface IProfile {
  id: number
  consultant: IConsultant
}

import * as React from "react"
import { Avatar, Box, Grid, Link, Typography } from "@mui/material"
import StarIcon from "@mui/icons-material/Star"
import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewProperty from "../../shared/components/ViewProperty"
import { styles } from "../../shared/styling/general"
import FormatDate from "../../shared/components/format/FormatDate"
import GoogleMapsGeoLink from "../../shared/components/GoogleMapsGeoLink"
import useAuth from "../../shared/hooks/useAuth"
import { LookupsRepository } from "../../shared/repositories/LookupsRepository"
import { useCallback, useEffect, useState } from "react"
import { MAINTENANCE_LS_KEY } from "../../config/config"
import { IMaintenance } from "../../shared/models/IMaintenance"
import { useLocalStorage } from "beautiful-react-hooks"
import { BASE_URL, BASE_URL_RELEASE } from "../../shared/utilities/request_utility"

const lookupsRepository = new LookupsRepository()

interface IDateTimeZone {
  date: string
  time: string
  timezone: string
}

/**
 * Displays general information about the consultant.
 *
 * @returns {React.FunctionComponent} the consultant's profile.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { currentUser } = useAuth()
  const consultant = currentUser?.user.profile.consultant

  const [serverDateTimeZone, setServerDateTimeZone] = useState<IDateTimeZone | null>(null)
  const [browserDateTimeZone, setBrowserDateTimeZone] = useState<IDateTimeZone | null>(null)
  const [initial, setInitial] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maintenance, setMaintenance] = useLocalStorage<IMaintenance | null>(MAINTENANCE_LS_KEY, null)

  // Handle fetching the date, time, and timezone
  const getBrowserDateTimeZone = useCallback(() => {
    const now = new Date()
    const date = now.toDateString()
    const time = now.toLocaleTimeString()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return {
      date,
      time,
      timezone,
    }
  }, [])

  useEffect(() => {
    if (!initial) {
      setInitial(true)
      setBrowserDateTimeZone(getBrowserDateTimeZone())
      void (async function getInvoice() {
        try {
          const newDateTimeZone = await lookupsRepository.lookup<IDateTimeZone>("server_datetime")
          setServerDateTimeZone(newDateTimeZone)
        } catch (error) {
          console.error("Failed to get server datetime", error)
        }
      })()
    }
  }, [serverDateTimeZone])

  return (
    <WorkflowPage>
      {consultant !== undefined && (
        <Box sx={styles.page}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    alt={`${consultant.first_name} ${consultant.last_name}`}
                    sx={{ width: 64, height: 64 }}
                    src={consultant.profile_image_readonly.exists !== false ? consultant.profile_image_readonly.read_url : ""}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="h3" component="h2">
                    {consultant.first_name} {consultant.last_name}
                    <Box component="small" sx={styles.consultant.identifier}>
                      {consultant.identifier}
                    </Box>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  {currentUser !== undefined && currentUser !== null && (
                    <ViewProperty label="Last Login">
                      <FormatDate value={currentUser.user.last_login} />
                    </ViewProperty>
                  )}

                  <ViewProperty label={<>Email {consultant.preferred_com === "email" && <StarIcon fontSize="small" />}</>}>
                    <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email}>`}>{consultant.email}</Link>
                  </ViewProperty>

                  <ViewProperty label={<>Email 2</>}>
                    <Link href={`mailto:${consultant.first_name} ${consultant.last_name}<${consultant.email_2}>`}>
                      {consultant.email_2}
                    </Link>
                  </ViewProperty>

                  <ViewProperty
                    label={
                      <>
                        Phone {consultant.phone_type !== "" && <small>({consultant.phone_type})</small>}
                        {consultant.preferred_com === "phone" && <StarIcon fontSize="small" />}
                      </>
                    }
                  >
                    <Link href={`tel:${consultant.phone}`}>{consultant.phone}</Link>
                  </ViewProperty>

                  <ViewProperty label={<>Phone 2</>}>
                    <Link href={`tel:${consultant.phone_2}`}>{consultant.phone_2}</Link>
                  </ViewProperty>

                  <Box sx={{ mt: 2 }}>
                    <ViewProperty label="Address">
                      <Grid container alignContent="start">
                        <Grid item xs={12}>
                          {consultant.address}
                        </Grid>
                        <Grid item xs={12}>
                          {consultant.address_2}
                        </Grid>
                        <Grid item xs={12}>
                          {consultant.city}, {consultant.state_region} {consultant.postal_code}
                          {consultant.country}
                        </Grid>
                      </Grid>
                    </ViewProperty>

                    <ViewProperty label="Long, Lat">
                      <GoogleMapsGeoLink latitude={consultant.latitude} longitude={consultant.longitude} />
                      {" | "}
                      {consultant.radius} miles
                    </ViewProperty>

                    <ViewProperty label="Will Travel Countries">
                      {consultant.will_travel_regions?.length !== undefined && consultant.will_travel_regions.length > 0
                        ? consultant.will_travel_regions.join(", ")
                        : "None"}
                    </ViewProperty>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Grid container>
                    {serverDateTimeZone !== null && (
                      <>
                        <Grid item xs={6}>
                          <ViewProperty vertical label="Server Time">
                            <ul>
                              <li>{serverDateTimeZone.date}</li>
                              <li>{serverDateTimeZone.time}</li>
                              <li>{serverDateTimeZone.timezone}</li>
                            </ul>
                          </ViewProperty>
                        </Grid>
                        <Grid item xs={6}>
                          <ViewProperty vertical label="Local Time">
                            <ul>
                              <li>{browserDateTimeZone?.date}</li>
                              <li>{browserDateTimeZone?.time}</li>
                              <li>{browserDateTimeZone?.timezone}</li>
                            </ul>
                          </ViewProperty>
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <ViewProperty vertical label={<>API {maintenance?.useRelease === true && <small>(Release)</small>}</>}>
                        <ul>
                          {maintenance?.useRelease === true ? (
                            <>
                              <li>{BASE_URL}</li>
                              <li>
                                <strong>{BASE_URL_RELEASE}</strong> <small>(Release)</small>
                              </li>
                            </>
                          ) : (
                            <>
                              <li>
                                <strong>{BASE_URL}</strong>
                              </li>
                              <li>{BASE_URL_RELEASE} <small>(Release)</small></li>
                            </>
                          )}
                        </ul>
                      </ViewProperty>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </WorkflowPage>
  )
}

export default ViewPage

import { IWorkAssignmentConsultant } from "./IWorkAssignmentConsultant"
import { IExpenseReportLineItem } from "./IExpenseReportLineItem"
import { IStorageFile } from "./IStorageFile"
import { IWorkAssignment } from "./IWorkAssignment"
import { IAdminItem } from "./components/IAdminItem"
import { IPayPeriod } from "./main/IPayPeriod"

export const EXPENSE_REPORTS_ENDPOINT = "expense_reports"

export interface IExpenseReport {
  id: number
  identifier: string
  name: string
  report_ready: string | null
  received: string | null
  paid: string | null
  pay_period: string | null
  adp_pay_period: IPayPeriod | null
  adp_pay_period_end_date: string | null
  file: IStorageFile | undefined
  notes: string
  work_assignment_consultants: IWorkAssignmentConsultant[]
  admin_review_work_assignments: IWorkAssignment[]
  tech_review_work_assignments: IWorkAssignment[]
  tech_review_2_work_assignments: IWorkAssignment[]
  line_items: IExpenseReportLineItem[]
  admin_items: IAdminItem[]
  total_amount: number
  total_admin_hours: number
  created: string
  updated: string
}

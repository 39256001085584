import { Grid, Typography } from "@mui/material"
import * as React from "react"
import { styles } from "../styling/general"

interface IProps {
  label: number | string | React.ElementType | any
  children: number | string | React.ElementType | any
  vertical?: boolean
  show?: boolean
  spacing?: number
}

/**
 * A standardized way to display a property.
 *
 * @param {IProps} props see IProps for details.
 * @class
 */
const ViewProperty: React.FunctionComponent<IProps> = (props: IProps) => {
  const { label, children, vertical = false, show = true, spacing = 2 } = props

  return show ? (
    <Grid container spacing={spacing} sx={styles.viewProperty} direction={vertical ? "column" : "row"}>
      <Grid item md={6}>
        <Typography fontWeight={600}>{label}</Typography>
      </Grid>
      <Grid item md={6}>
        <Typography variant="body1" component="div">
          {children}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default ViewProperty

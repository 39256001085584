import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { Alert, Box, Button, Chip, Divider, Grid, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import { RestRepository } from "../../shared/repositories/RestRepository"
import FormatDate from "../../shared/components/format/FormatDate"
import { IWorkAssignment, WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT, workAssignmentLocation } from "../../shared/models/IWorkAssignment"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import { CONNECTION_ERROR, IConnectionError } from "../../shared/models/IConnectionError"
import { navigate } from "gatsby"
import { TECH_REVIEWS_URL } from "../../config/urls"
import { IFile } from "../../shared/models/IFile"
import { IWorkAssignmentFile, WORK_ASSIGNMENT_TECH_REVIEW_FILE_ENDPOINT } from "../../shared/models/IWorkAssignmentFile"
import { customerTypeName } from "../../shared/models/ICustomer"
import ViewProperty from "../../shared/components/ViewProperty"
import ExecutiveCard from "../../shared/components/ExecutiveCard"
import WorkAssignmentLocation from "../../shared/components/WorkAssignmentLocation"
import FilesEditor from "../work_assignments/components/FilesEditor"
import SignOffTechReviewDialog from "./components/SignOffTechReviewDialog"
import AcceptDeclineTechReviewButton from "./components/AcceptDeclineTechReviewButton"
import TechReviewAssignmentResponse from "./components/TechReviewAssignmentResponse"
import TechReviewAssignmentStatus from "./components/TechReviewAssignmentStatus"
import ChangeHtmlField from "../../shared/components/ChangeHtmlField"

const repository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT)
const fileRepository = new RestRepository<IWorkAssignmentFile>(WORK_ASSIGNMENT_TECH_REVIEW_FILE_ENDPOINT)

/**
 * View a selected work assignment that needs tech review.
 *
 * @returns {React.ReactElement} the component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: wa, error, loading, call: reloadWa } = useApiRead<IWorkAssignment>({ apiFunction: repository.read })
  const { tab, handleTabChange } = useTabPanel()
  const [saving, setSaving] = useState(false)
  const [openSignOffConfirm, setOpenSignOffConfirm] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  const hasAcceptedOrDeclined = useMemo(() => {
    if (wa !== undefined) {
      return wa.tech_review_accept !== null || wa.tech_review_decline !== null
    }
    return false
  }, [wa])

  const hasAccepted = useMemo(() => {
    if (wa !== undefined) {
      return wa.tech_review_accept !== null
    }
    return false
  }, [wa])

  const isComplete = useMemo(() => {
    if (wa !== undefined) {
      return wa.tech_review_sign_off !== null
    }
    return false
  }, [wa])

  const handleAcceptDeclineWa = useCallback(
    async (action: string = "accept") => {
      if (wa !== undefined) {
        setSaving(true)
        setSavingError(null)
        try {
          await repository.action(wa.id, action)
          if (action === "accept") {
            reloadWa()
          } else {
            await navigate(TECH_REVIEWS_URL)
          }
        } catch (reason: any) {
          if (reason.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
      }
    },
    [wa]
  )

  const handleOpenSignOffConfirm = useCallback(() => {
    setOpenSignOffConfirm(true)
  }, [])

  const handleSignOffYes = useCallback(
    async (hours: number) => {
      if (wa !== undefined) {
        setOpenSignOffConfirm(false)
        setSaving(true)
        setSavingError(null)
        try {
          const paging: any = { filters: [{ field: "hours", value: hours }] }
          await repository.action(wa.id, "reports_submitted_sign_off", paging)
          await navigate(TECH_REVIEWS_URL)
        } catch (reason: any) {
          if (reason.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
      }
    },
    [wa]
  )

  const handleSignOffNo = useCallback(() => {
    setOpenSignOffConfirm(false)
  }, [])

  const handleSaveFile = useCallback(
    async (f: IFile, isEdit: boolean = false, refresh: boolean = true) => {
      if (wa !== undefined) {
        const file: IWorkAssignmentFile = { ...f, work_assignment: wa.id }
        if (isEdit && file.id !== undefined) {
          const waFile = await fileRepository.edit(file, file.id)
          if (refresh) {
            reloadWa()
          }
          return waFile
        } else {
          const waFile = await fileRepository.add(file)
          if (refresh) {
            reloadWa()
          }
          return waFile
        }
      }
      return null
    },
    [wa]
  )

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading} />
        <ErrorMessage error={error} />
        <ErrorMessage error={savingError} />
        {wa !== undefined && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Typography variant="h4" onClick={reloadWa} sx={{ cursor: "pointer" }}>
                      Tech Review {wa.tech_review_number} - {workAssignmentLocation(wa)}
                    </Typography>
                  </Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Typography variant="subtitle1">
                      {wa.customer !== null && (
                        <>
                          {wa.customer.name} <sup>({customerTypeName(wa.customer)})</sup>
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Typography variant="subtitle2">{wa.account !== null && <>{wa.account.name}</>}</Typography>
                  </Grid>
                  <Grid item>{wa.is_consultant_report_late && <Chip label="Late" color="error" />}</Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <ViewLoading loading={loading || saving} message="Updating..." />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={styles.tabsBox}>
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Overview" />
                {((hasAccepted && !isComplete) || !hasAcceptedOrDeclined) && <Tab label="Files" />}
              </Tabs>
            </Box>
            <TabPanel index={0} value={tab}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center">
                    <Grid item xs={12}>
                      <Grid container alignItems="end">
                        <Grid item xs>
                          <Typography variant="h5">Status</Typography>
                        </Grid>
                        <Grid item>
                          <em>
                            <TechReviewAssignmentStatus workAssignment={wa} />
                          </em>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>1.</Grid>
                    <Grid item xs>
                      Accept / Decline Tech Review
                    </Grid>
                    <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                      {hasAcceptedOrDeclined ? (
                        <TechReviewAssignmentResponse workAssignment={wa} small={true} />
                      ) : (
                        <AcceptDeclineTechReviewButton disabled={saving || loading} onAcceptDeclineWa={handleAcceptDeclineWa} />
                      )}
                    </Grid>

                    {hasAccepted && (
                      <>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item>2.</Grid>
                        <Grid item xs>
                          Add Reviewed Report to Files &amp; Sign Off
                        </Grid>
                        <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                          {isComplete ? (
                            <>
                              <FormatDate value={wa.tech_review_due_date} />
                            </>
                          ) : (
                            <>
                              <SignOffTechReviewDialog open={openSignOffConfirm} onNo={handleSignOffNo} onYes={handleSignOffYes} />
                              <Button
                                onClick={handleOpenSignOffConfirm}
                                fullWidth
                                size="small"
                                variant="contained"
                                disabled={saving || loading}
                              >
                                Sign Off
                              </Button>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      {wa?.service_instructions_url !== null && wa.service_instructions_url !== "" && (
                        <Alert
                          severity="info"
                          sx={{ mt: 1, mb: 1 }}
                          action={
                            <Button component={Link} target="_blank" href={wa.service_instructions_url} sx={{ whiteSpace: "nowrap" }}>
                              Service Instructions
                            </Button>
                          }
                        >
                          Please review the Account Service Instructions before starting inspection.{" "}
                          {wa.service_instructions_change_date !== null && (
                            <>
                              Last updated <FormatDate value={wa.service_instructions_change_date} />.
                            </>
                          )}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Details</Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ mt: -2 }}>
                      <ViewProperty label="ID">
                        <Box sx={{ textAlign: "right" }}>{wa.identifier}</Box>
                      </ViewProperty>
                      <ViewProperty label="Inspection Type">
                        <Box sx={{ textAlign: "right" }}>{wa.inspection_type_name !== "" ? wa.inspection_type_name : <>Not Set</>}</Box>
                      </ViewProperty>
                      <ViewProperty label="Report Format">
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs>
                            {wa.report_format !== null ? wa.report_format?.name : <>Not Set</>}
                          </Grid>
                          {wa.report_format !== null && wa.report_format?.file.exists !== false && (
                            <Grid item>
                              <Button variant="outlined" size="small" target="__blank" href={wa.report_format?.file.read_url}>
                                Download
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ mt: -2 }}>
                      <ViewProperty label="Priority">
                        <Box sx={{ textAlign: "right" }}>{wa.priority_name}</Box>
                      </ViewProperty>
                      <ViewProperty label="Visit Date">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatDate value={wa.visit_date} nullText="Not Scheduled" />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Due Date">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatDate value={wa.tech_review_due_date} />
                        </Box>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {wa.executive !== null && <ExecutiveCard executive={wa.executive} />}
                      {wa.technical_admin !== null && (
                        <Box sx={{ mt: 2 }}>
                          <ExecutiveCard executive={wa.technical_admin} label="Technical Admin" />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <WorkAssignmentLocation location={wa.location} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs>
                      <Typography variant="h5">Notes</Typography>
                    </Grid>
                    <Grid item>
                      <ChangeHtmlField
                        modelId={wa.id}
                        item={wa}
                        field="tech_review_notes"
                        title="Notes"
                        onChange={reloadWa}
                        value={wa.tech_review_notes}
                        repository={repository}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {wa.tech_review_notes !== "" ? (
                        <div dangerouslySetInnerHTML={{ __html: wa.tech_review_notes }} />
                      ) : (
                        <strong>No notes have been entered.</strong>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={tab}>
              <FilesEditor
                files={wa.files?.filter(f => f.id)}
                onChange={reloadWa}
                onSaveFile={handleSaveFile}
                haveReportsSubmitted={wa.progress_report_review_tech_sign_off === null}
                hasAccepted={hasAccepted}
                location={wa.location}
              />
            </TabPanel>
          </>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage

import * as React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField, TextFieldProps
} from "@mui/material"
import { IAdminItem } from "../../../shared/models/components/IAdminItem"
import { useCallback, useEffect, useState } from "react"
import { IListItem } from "../../../shared/models/components/IListItem"
import SelectFilteredSingle from "../../../shared/components/SelectFilteredSingle"
import { CUSTOMER_ENDPOINT, ICustomer } from "../../../shared/models/ICustomer"
import { ACCOUNT_ENDPOINT, IAccount } from "../../../shared/models/IAccount"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import { EXPENSE_REPORT_CATEGORIES_ENDPOINT } from "../../../shared/models/IExpenseReportCategory"
import DialogControls from "../../../shared/components/DialogControls"
import moment from "moment"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT } from "../../../config/config"

const customerRepository = new RestRepository<ICustomer | IListItem>(CUSTOMER_ENDPOINT)
const accountRepository = new RestRepository<IAccount | IListItem>(ACCOUNT_ENDPOINT)
const categoryRepository = new RestRepository<IListItem>(EXPENSE_REPORT_CATEGORIES_ENDPOINT)

interface IProps {
  onChange: (adminItem: IAdminItem | null) => void
  editAdminItem: IAdminItem | null
  open: boolean
}

/**
 * A single admin entry for the engineer to fill out.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the admin entry
 */
const AdminItemDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onChange, open, editAdminItem } = props

  const [customer, setCustomer] = useState<IListItem | null>(null)
  const [account, setAccount] = useState<IListItem | null>(null)
  const [category, setCategory] = useState<IListItem | null>(null)
  const [description, setDescription] = useState<string>("")
  const [hour, setHour] = useState<number>(0)
  const [minute, setMinute] = useState<number>(0)
  const [date, setDate] = useState<moment.Moment | null>(null)

  const handleClose = useCallback(() => {
    setCustomer(null)
    setAccount(null)
    setCategory(null)
    setDate(null)
    setDescription("")
    setHour(0)
    setMinute(0)
    onChange(null)
  }, [])

  const handleSave = useCallback(() => {
    const newAdminItem: IAdminItem = {
      customer,
      account,
      expense_report_category: category,
      on_date: date !== null ? date.clone() : null,
      description,
      hours: hour + minute / 60
    }
    onChange(newAdminItem)
    setCustomer(null)
    setAccount(null)
    setCategory(null)
    setDate(null)
    setDescription("")
    setHour(0)
    setMinute(0)
  }, [customer, account, category, description, date, hour, minute])

  const handleCustomer = useCallback((customer1: IListItem | null) => {
    setCustomer(customer1)
    setAccount(null)
  }, [])

  const handleAccount = useCallback((account1: IListItem | null) => {
    setAccount(account1)
  }, [])

  const handleCategory = useCallback((category1: IListItem | null) => {
    setCategory(category1)
  }, [])

  const handleDateChange = useCallback((e: moment.Moment | null) => {
    if (e !== null) {
      setDate(moment(e))
    } else {
      setDate(null)
    }
  }, [])

  const handleDescriptionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }, [])

  const handleHourChange = useCallback((event: SelectChangeEvent) => {
    setHour(Number(event.target.value))
  }, [])

  const handleMinuteChange = useCallback((event: SelectChangeEvent) => {
    setMinute(Number(event.target.value))
  }, [])

  useEffect(() => {
    if (open) {
      if (editAdminItem !== null) {
        setCustomer(editAdminItem.customer as IListItem)
        setAccount(editAdminItem.account as IListItem)
        setCategory(editAdminItem.expense_report_category as IListItem)
        setDate(editAdminItem.on_date !== null ? moment(editAdminItem.on_date).clone() : null)
        setDescription(editAdminItem.description as string)
        setHour(Math.floor(editAdminItem.hours as number))
        setMinute(((editAdminItem.hours as number) - Math.floor(editAdminItem.hours as number)) * 60)
      }
    } else {
      setCustomer(null)
      setAccount(null)
      setCategory(null)
      setDate(null)
      setDescription("")
      setHour(0)
      setMinute(0)
    }
  }, [editAdminItem, open])

  return (
    <>
      <Dialog open={open} maxWidth="md" fullWidth>
        <DialogTitle>{editAdminItem !== null ? "Edit" : "Add"} Admin Time</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <SelectFilteredSingle
                name="customer"
                label="Customer"
                defaultValue={customer}
                repository={customerRepository}
                onChange={handleCustomer}
              />
            </Grid>
            <Grid item xs={4}>
              {customer != null && (
                <SelectFilteredSingle
                  name="account"
                  label="Account"
                  filters={[
                    {
                      field: "customer",
                      value: customer?.id
                    }
                  ]}
                  defaultValue={account}
                  repository={accountRepository}
                  onChange={handleAccount}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <SelectFilteredSingle
                name="category"
                label="Category"
                defaultValue={category}
                repository={categoryRepository}
                onChange={handleCategory}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField label="Description"
                         value={description}
                         onChange={handleDescriptionChange}
                         helperText="Max length of 300 characters."
                         fullWidth
                         inputProps={{ maxlength: 299 }} />
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date"
                  onChange={handleDateChange}
                  value={date}
                  inputFormat={DATE_INPUT_FORMAT}
                  renderInput={(params: TextFieldProps) => (
                    <TextField required={false}
                               fullWidth
                               {...params}
                               InputLabelProps={{ shrink: true }} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="hours-label">Hours</InputLabel>
                <Select labelId="hours-label" id="hours-select" label="Hours" value={`${hour}`}
                        onChange={handleHourChange}>
                  {[...Array(41).keys()].map(hour => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="minutes-label">Minutes</InputLabel>
                <Select labelId="minutes-label" id="minutes-select" label="Minutes" value={`${minute}`}
                        onChange={handleMinuteChange}>
                  <MenuItem value={0} selected>
                    00
                  </MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={45}>45</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogControls onSave={handleSave} onCancel={handleClose} />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AdminItemDialog

import { ICustomer } from "./ICustomer"
import { ILocation } from "./ILocation"
import { IAccount } from "./IAccount"

/**
 * Get the contact's phone number with extension.
 *
 * @param {IContact} contact to get the number from.
 * @returns {string} the phone number.
 */
export const getContactPhone = (contact: IContact): string => {
  const ext = contact.phone_ext !== undefined && contact.phone_ext !== null && contact.phone_ext !== "" ? `x${contact.phone_ext}` : ""
  return `${contact.phone}${ext}`
}

export interface IContact {
  id: number
  first_name: string
  last_name: string
  job_title: string

  phone: string
  phone_ext: string

  email: string

  notes: string
  customers: ICustomer[]
  locations: ILocation[]
  accounts: IAccount[]

  created: string
  updated: string
}

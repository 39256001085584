import * as React from "react"
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"

import WorkflowPage from "../../shared/components/WorkflowPage"
import ViewLoading from "../../shared/components/ViewLoading"
import ErrorMessage from "../../shared/components/ErrorMessage"

import { useApiRead } from "../../shared/hooks/useApiRead"
import { styles } from "../../shared/styling/general"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../shared/models/IExpenseReport"
import FormatNumber from "../../shared/components/format/FormatNumber"
import FormatDate from "../../shared/components/format/FormatDate"
import StorageFileDownloadButton from "../../shared/components/StorageFileDownloadButton"
import ConsultantRate from "../../shared/components/ConsultantRate"
import TruncateText from "../../shared/components/TruncateText"
import { IListItem } from "../../shared/models/components/IListItem"
import { useMemo } from "react"
import PayPeriodDisplay from "./components/PayPeriodDisplay"
import AdpPayPeriodDisplay from "./components/AdpPayPeriodDisplay"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)

/**
 * View a selected expense report.
 *
 * @returns {React.FC} the component.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: expenseReport, error, loading, call: refresh } = useApiRead<IExpenseReport>({ apiFunction: repository.read })

  const showTotalAmount = useMemo(() => {
    return (
      expenseReport !== undefined &&
      (expenseReport.work_assignment_consultants.length > 0 ||
        expenseReport.tech_review_work_assignments.length > 0 ||
        expenseReport.tech_review_2_work_assignments.length > 0 ||
        expenseReport.line_items.length > 0)
    )
  }, [expenseReport])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading} />
        <ErrorMessage error={error} />
        {expenseReport !== undefined && expenseReport !== null && (
          <Grid container spacing={2}>
            <Grid item md={expenseReport.notes !== "" ? 9 : 12} xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography variant="h3" component="h2" onClick={refresh} sx={{ cursor: "pointer" }}>
                    ER-{expenseReport.id}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">
                    {expenseReport.pay_period !== null ? (
                      <PayPeriodDisplay payPeriod={expenseReport.pay_period} />
                    ) : (
                      <AdpPayPeriodDisplay adpPayPeriod={expenseReport.adp_pay_period} />
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <StorageFileDownloadButton file={expenseReport.file} title="Download" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <strong>Report Ready</strong>
                    </Grid>
                    <Grid item>
                      <FormatDate value={expenseReport.report_ready} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {expenseReport.notes !== "" && (
              <Grid item md={3} xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Box sx={{ pb: 1 }}>
                    <strong>Notes</strong>
                  </Box>
                  {expenseReport.notes}
                </Paper>
              </Grid>
            )}
            {expenseReport.work_assignment_consultants.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="end">
                    <Grid item xs>
                      <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                        Work Assignments
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>ID</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseReport.work_assignment_consultants.map((wac, index) => {
                          const workAssignment = wac.work_assignment
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.customer?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.account?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.location.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <ConsultantRate wac={wac} showDetails />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
            {expenseReport.line_items.length > 0 && (
              <Grid item xs={12}>
                <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                  Line Items
                </Typography>
                <TableContainer>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell sx={{ width: "80%" }}>Description</TableCell>
                        <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {expenseReport?.line_items.map((lineItem, index) => (
                        <TableRow key={lineItem.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{lineItem.description}</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>
                            <FormatNumber value={lineItem.amount} twoDecimalPlaces />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {showTotalAmount && expenseReport.total_amount > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs />
                  <Grid item>
                    <Typography variant="h5">Total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {expenseReport.total_amount === 0 ? "$0" : <FormatNumber value={expenseReport.total_amount} twoDecimalPlaces />}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {expenseReport.admin_review_work_assignments.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="end">
                    <Grid item xs>
                      <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                        Admin Review
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>ID</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseReport.admin_review_work_assignments.map((workAssignment, index) => {
                          return (
                            <TableRow key={workAssignment.id}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.customer?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.account?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.location.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <FormatNumber value={workAssignment.admin_review_hours} prefixUnits={false} twoDecimalPlaces />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
            {expenseReport.tech_review_work_assignments.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="end">
                    <Grid item xs>
                      <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                        Tech Review
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>ID</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseReport.tech_review_work_assignments.map((workAssignment, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.customer?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.account?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.location.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <FormatNumber value={workAssignment.tech_review_hours} prefixUnits={false} twoDecimalPlaces />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
            {expenseReport.tech_review_2_work_assignments.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="end">
                    <Grid item xs>
                      <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                        Tech Review 2
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>ID</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseReport.tech_review_2_work_assignments.map((workAssignment, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.identifier}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.customer?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.account?.name}</TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>{workAssignment.location.name}</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <FormatNumber value={workAssignment.tech_review_2_hours} prefixUnits={false} twoDecimalPlaces />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}
            {expenseReport.admin_items.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Grid container alignItems="end">
                    <Grid item xs>
                      <Typography component="h4" variant="h4" sx={{ mt: 2 }}>
                        Admin Time
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Customer</TableCell>
                          <TableCell>Account</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {expenseReport.admin_items.map((adminItem, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>
                                <TruncateText text={(adminItem.customer as IListItem)?.name} placement="top-start" />
                              </TableCell>
                              <TableCell>
                                <TruncateText text={(adminItem.account as IListItem)?.name} placement="left-start" />
                              </TableCell>
                              <TableCell>
                                <TruncateText text={(adminItem.expense_report_category as IListItem)?.name} placement="left-start" />
                              </TableCell>
                              <TableCell>
                                <FormatDate value={adminItem.on_date} />
                              </TableCell>
                              <TableCell>
                                <TruncateText text={adminItem.description} placement="left-start" />
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                <FormatNumber value={adminItem.hours} prefixUnits={false} twoDecimalPlaces />
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </>
            )}

            {expenseReport.total_admin_hours > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs />
                  <Grid item>
                    <Typography variant="h5">Total</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {expenseReport.total_admin_hours === 0 ? (
                        "0.00"
                      ) : (
                        <FormatNumber value={expenseReport.total_admin_hours} prefixUnits={false} twoDecimalPlaces />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage

import * as React from "react"
import { IStorageFile } from "../models/IStorageFile"
import { Box, Button } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download"
import FormatDate from "./format/FormatDate"

interface IProps {
  file: IStorageFile | undefined
  title?: string | null
  showDate?: boolean
}

// todo: put in shared library

/**
 * A file download button for a storage file.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the download button.
 */
const StorageFileDownloadButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { file, title = null, showDate = false } = props

  return (
    <>
      {file?.exists !== undefined && file.exists !== false && (
        <>
          <Button onClick={() => window.open(file.read_url, "_blank")} startIcon={<DownloadIcon />}>
            <Box component="span" sx={{ mr: showDate ? 2 : 0 }}>
              {title === null ? file.filename : title}
            </Box>
            {showDate && <FormatDate value={file.exists as string} />}
          </Button>
        </>
      )}
    </>
  )
}
export default StorageFileDownloadButton

import { IContact } from "./IContact"
import { IAccount } from "./IAccount"
import { ISpecialty } from "./ISpecialty"
import { ILocationFile } from "./ILocationFile"

/**
 * Convert a location to a string.
 *
 * @param {ILocationFile} location to convert.
 * @returns {string} the location as a string.
 */
export const locationToString = (location: ILocation): string => {
  return `${location.address}, ${location.address_2 !== "" && location.address_2 !== null ? `${location.address_2}, ` : ""} 
          ${location.city}, ${location.state_region} ${location.postal_code},  ${location.country}`
}

export interface ILocation {
  id: number
  name: string
  aliases: string[] | null

  address: string
  address_2: string
  city: string
  state_region: string
  country: string
  postal_code: string

  latitude: number
  longitude: number

  area?: number

  property_damage: number
  business_interruption: number
  total_insured_value: number

  contacts: IContact[]
  accounts: IAccount[]
  specialty: ISpecialty | null
  files: ILocationFile[]
}

import { IWorkAssignment, workAssignmentConsultantHasResponded } from "../models/IWorkAssignment"
import useAuth from "../hooks/useAuth"
import { Chip } from "@mui/material"
import * as React from "react"

interface IProps {
  wa: IWorkAssignment | null
  small: boolean
}

/**
 * Displays the work assignment response.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the assignment response
 */
const AssignmentResponse: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { currentUser } = useAuth()
  const { wa, small = true } = props
  const [hasResponded, waConsultant] = workAssignmentConsultantHasResponded(wa, currentUser?.user)
  if (hasResponded) {
    if (waConsultant?.accepted === true) {
      return (
        <Chip
          color="success"
          label={`${small ? "" : "Response: "}Accepted`}
          size={small ? "small" : "medium"}
          sx={small ? {} : { ml: 2 }}
        />
      )
    }
    if (waConsultant?.declined === true) {
      return (
        <Chip
          color="error"
          label={`${small ? "" : "Response: "}Unavailable`}
          size={small ? "small" : "medium"}
          sx={small ? {} : { ml: 2 }}
        />
      )
    }
  }
  return (
    <Chip color="warning" label={`${small ? "" : "Response: "}Waiting`} size={small ? "small" : "medium"} sx={small ? {} : { ml: 2 }} />
  )
}

export default AssignmentResponse

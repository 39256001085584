import useAuth from "./useAuth"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "@reach/router"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"

interface IUseApiReadResponse<T> {
  call: () => void
  loading: boolean
  data: T | undefined
  error: IConnectionError | undefined
}

export interface IUseApiReadProps<T> {
  apiFunction: (id: string | number) => Promise<T>
  dontCallOnMount?: boolean
  itemId?: number | undefined
}

/**
 * Use this hook to read from the api.
 *
 * @param {IUseApiReadProps} props See IUseApiReadProps<T> for details.
 * @returns {IUseApiReadResponse} see IUseApiReadResponse for details.
 */
export const useApiRead = <T>(props: IUseApiReadProps<T>): IUseApiReadResponse<T> => {
  const { apiFunction, dontCallOnMount, itemId } = props
  const { logoutWithConnectionError } = useAuth()
  const { id } = itemId !== undefined ? { id: itemId } : useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T | undefined>()
  const [error, setError] = useState<IConnectionError | undefined>()

  const call = useCallback(() => {
    setLoading(true)
    apiFunction(id)
      .then(results => {
        setData(results)
        setError(undefined)
      })
      .catch((reason: any) => {
        setData(undefined)
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      })
      .finally(() => setLoading(false))
  }, [apiFunction, id, logoutWithConnectionError])

  useEffect(() => {
    if (dontCallOnMount === undefined || !dontCallOnMount) {
      call()
    }
  }, [call, dontCallOnMount])

  return {
    call,
    loading,
    data,
    error,
  }
}

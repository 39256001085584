import { IAccount } from "./IAccount"
import { ILocation } from "./ILocation"
import IUser from "./IUser"
import { IWorkAssignmentMessage } from "./IWorkAssignmentMessage"
import { IWorkAssignmentConsultant } from "./IWorkAssignmentConsultant"
import { IConsultant } from "./IConsultant"
import { ICustomer } from "./ICustomer"
import { IWorkAssignmentFile } from "./IWorkAssignmentFile"
import { IReportFormat } from "./IReportFormat"

export const WORK_ASSIGNMENT_ENDPOINT = "work_assignments"
export const WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT = "work_assignment_tech_reviews"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT = "expense_report_work_assignment_tech_reviews"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT = "expense_report_work_assignment_admin_reviews"

export interface IWorkAssignment {
  id: number
  identifier: string
  name: string
  inspection_type: string
  inspection_type_name: string
  is_consultant_report_late: boolean
  executive: IUser | null
  technical_admin: IUser | null

  priority: number
  priority_name: string
  archive: boolean
  ignore: boolean
  due_date_days_after_visit: number

  due_date: string
  visit_date: string
  issued_date: string
  consultant_due_date: string

  total_insured_value?: number
  property_damage?: number
  business_interruption?: number
  invoice_amount: number
  report_format: IReportFormat
  service_instructions_url: string | null
  service_instructions_change_date: string | null

  notes_consultant: string
  contact_notes: string
  tech_review_notes: string

  progress_consultant_requests_sent: string
  progress_consultant_requests_sent_by: IUser

  progress_consultant_request_confirmed: string
  progress_consultant_request_confirmed_by: IUser

  progress_consultant_pre_survey_received: string
  progress_consultant_pre_survey_received_by: IUser

  progress_consultant_docs_ready: string
  progress_consultant_docs_ready_by: IUser

  progress_consultant_reports_submitted: string

  progress_report_review_admin_sign_off: string
  progress_report_review_admin_sign_off_by: IUser

  progress_report_review_tech_sign_off: string
  progress_report_review_tech_sign_off_by: IUser

  progress_report_sent_account: string
  progress_report_sent_account_by: IUser

  progress_invoice_sent_account: string
  progress_invoice_sent_account_by: IUser

  progress_account_paid: string
  progress_account_paid_by: IUser

  is_report_sent_account_late: boolean

  tech_reviewer_consultant: IConsultant
  tech_reviewer_2_consultant: IConsultant
  admin_reviewer_consultant: IConsultant

  tech_review_hours: number
  tech_review_2_hours: number
  admin_review_hours: number

  tech_review_due_date: string
  tech_review_2_due_date: string
  admin_review_due_date: string

  tech_reviewer_consultant_name: string
  tech_review_number: number
  tech_review_needed: boolean
  tech_review_accept: string | null
  tech_review_decline: string | null
  tech_review_sign_off: string | null
  tech_review_2_sign_off: string | null

  account: IAccount | null
  customer: ICustomer | null
  location: ILocation
  work_assignment_consultants: IWorkAssignmentConsultant[]
  messages: IWorkAssignmentMessage[]
  files: IWorkAssignmentFile[]
}

/**
 * Get the work assignment location as text.
 *
 * @param {IWorkAssignment} wa to convert to string.
 * @returns {string} the location or None
 */
export const workAssignmentLocation = (wa: IWorkAssignment): string => {
  if (wa.location !== null) {
    return `${wa.location.name} - ${wa.location.city}, ${wa.location.state_region}`
  }
  return "None"
}

/**
 * Check to see if a consultant has responded to a work assignment request.
 *
 * @param {IWorkAssignment | null} wa to check.
 * @param {IUser | undefined | null} user to check.
 * @returns {[boolean, IWorkAssignmentConsultant | null]} true and the work assignment consultant if accepted.
 */
export const workAssignmentConsultantHasResponded = (
  wa: IWorkAssignment | null,
  user: IUser | undefined | null
): [boolean, IWorkAssignmentConsultant | null] => {
  const waConsultant = wa?.work_assignment_consultants.filter(c => c.consultant.id === user?.profile.consultant.id)
  if (waConsultant !== undefined && waConsultant.length > 0) {
    return [waConsultant.length > 0, waConsultant[0]]
  }
  return [false, null]
}

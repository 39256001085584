import * as React from "react"
import { styles } from "../styling/general"
import { TableRow, Theme } from "@mui/material"
import { SxProps } from "@mui/system"
import { useCallback } from "react"

interface IProps {
  item: any
  children: any
  selected?: boolean
  onSelected: (item: any) => void
  sx?: SxProps<Theme>
}

/**
 * Select a row on table by clicking on it.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the table row selector.
 */
const TableRowSelect: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { item, children, sx, onSelected, selected = false } = props

  const handleClick = useCallback((event: any) => {
    if (event.target.tagName === "TD") {
      onSelected(item)
    }
  }, [])

  return (
    <TableRow
      hover
      sx={{
        ...styles.tableSelect,
        ...sx,
        backgroundColor: selected ? "#eee" : "transparent",
      }}
      onClick={handleClick}
    >
      {children}
    </TableRow>
  )
}

export default TableRowSelect

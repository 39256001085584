import { IWorkAssignment } from "./IWorkAssignment"
import { IConsultant } from "./IConsultant"

export const WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "work_assignment_consultants"
export const EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT = "expense_report_work_assignment_consultants"

export interface IWorkAssignmentConsultant {
  id: number
  amount: number
  accepted: boolean
  declined: boolean
  request_sent: boolean
  hourly_rate: boolean
  hours: number
  total_amount: number
  priority: number
  created: string
  updated: string
  work_assignment: IWorkAssignment
  consultant: IConsultant
}

import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api"
import { ILocation } from "../models/ILocation"
import { Grid, Typography } from "@mui/material"
import { icon, PushpinIcon } from "../utilities/icons_utilities"
import theme from "../themes/default_theme"
import { DEFAULT_CENTER, GOOGLE_MAPS } from "../../config/config"

interface IProps {
  location: ILocation
  zoom?: number
  height?: string
}

/**
 * Display a map for the work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the work assignment map.
 */
const WorkAssignmentMap: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { location, zoom = 19, height = "400px" } = props
  const [center, setCenter] = useState<{ lat: number; lng: number }>(DEFAULT_CENTER)

  const [selected, setSelected] = useState<ILocation>()
  const [infoOpen, setInfoOpen] = useState(false)
  const [markerMap, setMarkerMap] = useState({})
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const { isLoaded } = useJsApiLoader(GOOGLE_MAPS)

  useEffect(() => {
    if (map !== null) {
      setCenter({ lat: location.latitude, lng: location.longitude })
    }
  }, [map, location])

  const markerLoadHandler = useCallback((marker: any, name: any) => {
    setMarkerMap(prevState => ({ ...prevState, [name]: marker }))
  }, [])

  const markerClickHandler = useCallback(
    (s: ILocation) => {
      setSelected(s)
      // Required so clicking a 2nd marker works as expected
      if (infoOpen) {
        setInfoOpen(false)
      }
      setInfoOpen(true)
    },
    [infoOpen]
  )

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{ flexGrow: "1", height }}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapTypeId="satellite"
    >
      {infoOpen && selected !== undefined && (
        <InfoWindow anchor={(markerMap as any)[`${selected.id}`]} onCloseClick={() => setInfoOpen(false)}>
          <Grid container alignContent="start">
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: "bold" }}>{selected.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              {selected.address}
            </Grid>
            <Grid item xs={12}>
              {selected.address_2}
            </Grid>
            <Grid item xs={12}>
              {selected.city}, {selected.state_region} {selected.postal_code}
              {selected.country}
            </Grid>
          </Grid>
        </InfoWindow>
      )}

      <Marker
        position={{ lat: location.latitude, lng: location.longitude }}
        icon={icon({
          path: PushpinIcon,
          fillColor: theme.palette.success,
        })}
        onLoad={marker => markerLoadHandler(marker, `${location.id}`)}
        onClick={() => markerClickHandler(location)}
      />
    </GoogleMap>
  ) : (
    <></>
  )
}

export default WorkAssignmentMap

import * as React from "react"
import { Box } from "@mui/material"
import { isTruncated, truncateString } from "../utilities/format_utility"
import HtmlTooltip from "./HtmlToolTip"

interface IProps {
  text?: string | null
  altText?: string
  num?: number
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
}

/**
 * This component will truncate text and show a tooltip if needed.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The truncated text with a tooltip.
 */
const TruncateText: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { text = "", altText, placement = "right", num = 30 } = props
  const textUpdated = text === null ? "" : text

  return (
    <HtmlTooltip title={<Box dangerouslySetInnerHTML={{ __html: altText !== undefined ? altText : textUpdated }} />} placement={placement}>
      {isTruncated(textUpdated, num) || altText !== undefined ? <Box>{truncateString(textUpdated, num)}</Box> : <>{textUpdated}</>}
    </HtmlTooltip>
  )
}

export default TruncateText

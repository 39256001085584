import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import moment from "moment"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT, workAssignmentLocation } from "../../shared/models/IWorkAssignment"
import { IUseApiPagedResultsProps, useApiPaged } from "../../shared/hooks/useApiPaged"
import ErrorMessage from "../../shared/components/ErrorMessage"
import TableRowSelect from "../../shared/components/TableRowSelect"
import TableLoading from "../../shared/components/TableLoading"
import FormatDate from "../../shared/components/format/FormatDate"
import { navigate } from "gatsby"
import { WORK_ASSIGNMENT_VIEW_URL } from "../../config/urls"
import TablePaging from "../../shared/components/TablePaging"
import TableOrdering from "../../shared/components/TableOrdering"
import ListLoading from "../../shared/components/ListLoading"
import FormatNumber from "../../shared/components/format/FormatNumber"
import AssignmentStatus from "../../shared/components/AssignmentStatus"
import SortDialog from "./components/SortDialog"
import { styles } from "../../shared/styling/general"
import { DICTIONARY_ENTRIES } from "../../config/config"
import DictionaryDialog from "../../shared/components/DictionaryDialog"
import TruncateText from "../../shared/components/TruncateText"

const workAssignmentRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

const columnCount = 9

/**
 * A page to list all work assignments.
 *
 * @returns {React.FC} the work assignments list page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const [filterField, setFilterField] = useState<string>("all")
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))
  const props: IUseApiPagedResultsProps<IWorkAssignment> = { apiFunction: workAssignmentRepository.findAll }

  const {
    data: workAssignments,
    count,
    page,
    error,
    loading,
    limit,
    filters,
    ordering,
    handlePaging,
    handleFilter,
    handleOrdering,
  } = useApiPaged<IWorkAssignment>(props)

  const handleSelected = useCallback(
    (workAssignment: IWorkAssignment) => async () => {
      await navigate(`${WORK_ASSIGNMENT_VIEW_URL}/${workAssignment.id}`)
    },
    []
  )

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const field = event.target.value
      setFilterField(field)
      if (field !== "all") {
        let value = "true"
        if (field === "completed") {
          value = "false"
        }
        handleFilter([{ field, value }])
      } else {
        handleFilter([])
      }
    },
    [filterField]
  )

  useEffect(() => {
    if (filters !== undefined && filters.length > 0) {
      setFilterField(filters[0].field)
    } else {
      setFilterField("all")
    }
  }, [filters])

  return (
    <WorkflowPage>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <Box sx={{ m: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs sm md={3}>
            <FormControl fullWidth>
              <InputLabel>Filter</InputLabel>
              <Select value={filterField} label="Filter" onChange={handleChange} disabled={loading}>
                <MenuItem value="awaiting_response">Awaiting Response</MenuItem>
                <MenuItem value="visit_date">Visit Date Not Set</MenuItem>
                <MenuItem value="upcoming_visits">Upcoming Visits</MenuItem>
                <MenuItem value="visited_and_not_submitted">Visited & Not Submitted</MenuItem>
                <MenuItem value="in_progress">Report Writing in Progress</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="all">Show All</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {isSmall && (
            <Grid item>
              <SortDialog ordering={ordering} onOrdering={handleOrdering} />
            </Grid>
          )}
          <Grid item xs />
          <Grid item>
            <DictionaryDialog entries={DICTIONARY_ENTRIES} />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2 }}>
        {isSmall ? (
          <List>
            <ListLoading loading={loading} rows={limit} />
            {!loading &&
              workAssignments?.results.map(wa => (
                <React.Fragment key={wa.id}>
                  <ListItemButton onClick={handleSelected(wa)} sx={{ mt: 1, mb: 1 }}>
                    <ListItemText
                      primary={
                        <Typography sx={{ display: "inline" }} variant="h6">
                          {workAssignmentLocation(wa)}
                        </Typography>
                      }
                      secondary={
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {wa.customer?.name} | {wa.account?.name}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormatNumber value={wa.work_assignment_consultants[0].amount} />
                          </Grid>
                          <Grid item xs sx={{ textAlign: "center" }}>
                            {wa.inspection_type_name}
                          </Grid>
                          <Grid item>
                            <FormatDate value={wa.consultant_due_date} />
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItemButton>
                  <Divider />
                </React.Fragment>
              ))}
          </List>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="id" title="ID" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="customer__name" title="Customer" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="account__name" title="Account" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell>
                    <TableOrdering ordering={ordering} field="location__name" title="Location" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    <TableOrdering ordering={ordering} field="inspection_type" title="Inspection Type" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    <TableOrdering ordering={ordering} field="visit_date" title="Visit Date" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    <TableOrdering ordering={ordering} field="consultant_due_date" title="Due Date" onOrdering={handleOrdering} />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>Consultant Fee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableLoading loading={loading} columns={columnCount} rows={limit} />
                {!loading &&
                  workAssignments?.results.map(wa => {
                    const consultantDaysLate =
                      wa.is_consultant_report_late && wa.consultant_due_date !== null
                        ? moment().diff(moment(wa.consultant_due_date), "days")
                        : 0
                    return (
                      <TableRowSelect key={wa.id} item={wa} onSelected={handleSelected(wa)}>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{wa.identifier}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <AssignmentStatus wa={wa} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TruncateText text={wa.customer?.name} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TruncateText text={wa.account?.name} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <TruncateText text={workAssignmentLocation(wa)} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>{wa.inspection_type_name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          <FormatDate value={wa.visit_date} />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", ...styles.consultantReportIsLate(wa) }}>
                          <FormatDate value={wa.consultant_due_date} />
                          {wa.is_consultant_report_late && wa.consultant_due_date !== null && (
                            <Box component="small" sx={{ ml: 1 }}>
                              {consultantDaysLate} day{consultantDaysLate > 1 && "s"} late
                            </Box>
                          )}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", textAlign: "right" }}>
                          <FormatNumber
                            value={wa.work_assignment_consultants[0].amount}
                            suffixUnits={wa.work_assignment_consultants[0].hourly_rate ? "/hr" : ""}
                          />
                        </TableCell>
                      </TableRowSelect>
                    )
                  })}

                {!loading && workAssignments !== undefined && workAssignments.results.length < 1 && (
                  <TableRow>
                    <TableCell colSpan={columnCount}>No work assignments found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <TablePaging
        count={count}
        size={isSmall ? "large" : "medium"}
        total={workAssignments?.count}
        page={page !== undefined ? page : 1}
        handlePaging={handlePaging}
      />
    </WorkflowPage>
  )
}

export default IndexPage

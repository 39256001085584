import { Chip } from "@mui/material"
import * as React from "react"
import { IWorkAssignment } from "../../../shared/models/IWorkAssignment"

interface IProps {
  workAssignment: IWorkAssignment
  small: boolean
}

/**
 * Displays the tech reviewers response to accepting or declining the assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the tech reviewer's response.
 */
const TechReviewAssignmentResponse: React.FunctionComponent<IProps> = (props: IProps) => {
  const { workAssignment, small = true } = props
  if (workAssignment.tech_review_accept !== null) {
    return (
      <Chip color="success" label={`${small ? "" : "Response: "}Accepted`} size={small ? "small" : "medium"} sx={small ? {} : { ml: 2 }} />
    )
  }
  if (workAssignment.tech_review_decline !== null) {
    return (
      <Chip color="error" label={`${small ? "" : "Response: "}Unavailable`} size={small ? "small" : "medium"} sx={small ? {} : { ml: 2 }} />
    )
  }
  return (
    <Chip color="warning" label={`${small ? "" : "Response: "}Waiting`} size={small ? "small" : "medium"} sx={small ? {} : { ml: 2 }} />
  )
}

export default TechReviewAssignmentResponse

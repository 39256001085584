import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT, workAssignmentLocation } from "../../shared/models/IWorkAssignment"
import { useApiRead } from "../../shared/hooks/useApiRead"
import { RestRepository } from "../../shared/repositories/RestRepository"
import { Alert, Box, Button, Chip, Divider, Grid, Link, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material"
import ErrorMessage from "../../shared/components/ErrorMessage"
import ViewLoading from "../../shared/components/ViewLoading"
import { styles } from "../../shared/styling/general"
import WorkflowPage from "../../shared/components/WorkflowPage"
import TabPanel, { useTabPanel } from "../../shared/components/TabPanel"
import FormatDate from "../../shared/components/format/FormatDate"
import ViewProperty from "../../shared/components/ViewProperty"
import FormatNumber from "../../shared/components/format/FormatNumber"
import { customerTypeName } from "../../shared/models/ICustomer"
import WorkAssignmentMap from "../../shared/components/WorkAssignmentMap"
import WorkAssignmentLocation from "../../shared/components/WorkAssignmentLocation"
import ExecutiveCard from "../../shared/components/ExecutiveCard"
import ContactCard from "../../shared/components/ContactCard"
import { IWorkAssignmentConsultant, WORK_ASSIGNMENT_CONSULTANT_ENDPOINT } from "../../shared/models/IWorkAssignmentConsultant"
import useAuth from "../../shared/hooks/useAuth"
import { CONNECTION_ERROR, IConnectionError } from "../../shared/models/IConnectionError"
import AssignmentResponse from "../../shared/components/AssignmentResponse"
import FilesEditor from "./components/FilesEditor"
import { IWorkAssignmentFile, WORK_ASSIGNMENT_FILE_ENDPOINT } from "../../shared/models/IWorkAssignmentFile"
import { IFile } from "../../shared/models/IFile"
import ChangeDate from "./components/ChangeDate"
import AssignmentStatus from "../../shared/components/AssignmentStatus"
import CalendarDownloadButton from "./components/CalendarDownloadButton"
import DictionaryDialog from "../../shared/components/DictionaryDialog"
import { DICTIONARY_ENTRIES } from "../../config/config"
import AcceptDeclineButton from "./components/AcceptDeclineButton"
import { IPaging } from "../../shared/models/IPaging"
import { locationToString } from "../../shared/models/ILocation"
import { navigate } from "gatsby"
import { WORK_ASSIGNMENTS_URL } from "../../config/urls"
import SignOffDialog from "./components/SignOffDialog"
import TruncateText from "../../shared/components/TruncateText"
import DownloadIcon from "@mui/icons-material/Download"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)
const fileRepository = new RestRepository<IWorkAssignmentFile>(WORK_ASSIGNMENT_FILE_ENDPOINT)
const waConsultantRepository = new RestRepository<IWorkAssignmentConsultant>(WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)

/**
 * Displays the work assignment for the consultant.
 *
 * @returns {React.FC} the work assignment view.
 */
const ViewPage: React.FC = (): React.ReactElement => {
  const { data: wa, error, loading, call: reloadWa } = useApiRead<IWorkAssignment>({ apiFunction: waRepository.read })
  const { tab, handleTabChange } = useTabPanel()
  const { currentUser } = useAuth()
  const [saving, setSaving] = useState(false)
  const [openSignOffConfirm, setOpenSignOffConfirm] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  const wac = useMemo(() => {
    const waConsultant = wa?.work_assignment_consultants.filter(c => c.consultant.id === currentUser?.user.profile.consultant.id)
    if (waConsultant !== undefined && waConsultant.length > 0) {
      return waConsultant[0]
    }
    return null
  }, [wa, currentUser])

  const hasAcceptedOrDeclined = useMemo(() => {
    const waConsultant = wa?.work_assignment_consultants.filter(c => c.consultant.id === currentUser?.user.profile.consultant.id)
    if (waConsultant !== undefined && waConsultant.length > 0) {
      if (waConsultant[0].accepted) {
        return true
      }
      if (waConsultant[0].declined) {
        return true
      }
    }
    return false
  }, [wa])

  const hasAccepted = useMemo(() => {
    const waConsultant = wa?.work_assignment_consultants.filter(c => c.consultant.id === currentUser?.user.profile.consultant.id)
    if (waConsultant !== undefined && waConsultant.length > 0) {
      if (waConsultant[0].accepted) {
        return true
      }
    }
    return false
  }, [wa])

  const isComplete = useMemo(() => {
    return Boolean(wa?.progress_consultant_reports_submitted)
  }, [wa])

  const handleAcceptDeclineWa = useCallback(
    (action: string = "accept") =>
      async (comment?: string) => {
        if (wac !== null) {
          setSaving(true)
          setSavingError(null)
          try {
            const paging: IPaging = { filters: [{ field: "comment", value: comment }] }
            await waConsultantRepository.action(wac.id, action, paging)
            if (action === "accept") {
              reloadWa()
            } else {
              await navigate(WORK_ASSIGNMENTS_URL)
            }
          } catch (reason: any) {
            if (reason.response !== undefined) {
              setSavingError(reason.response)
            } else {
              setSavingError(CONNECTION_ERROR)
            }
          }
          setSaving(false)
        }
      },
    [wac]
  )

  const handleSignOff = useCallback(
    (action: string) => async () => {
      if (wa?.id !== undefined) {
        setSaving(true)
        setSavingError(null)
        try {
          await waRepository.action(wa.id, action)
          reloadWa()
        } catch (reason: any) {
          if (reason.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
      }
    },
    [wa]
  )

  const handleOpenSignOffConfirm = useCallback(() => {
    setOpenSignOffConfirm(true)
  }, [])

  const handleSignOffYes = useCallback(
    async (hours: number) => {
      if (wa?.id !== undefined) {
        setOpenSignOffConfirm(false)
        setSaving(true)
        setSavingError(null)
        try {
          const paging: IPaging = { filters: [{ field: "hours", value: hours }] }
          await waRepository.action(wa.id, "reports_submitted_sign_off", paging)
          reloadWa()
        } catch (reason: any) {
          if (reason.response !== undefined) {
            setSavingError(reason.response)
          } else {
            setSavingError(CONNECTION_ERROR)
          }
        }
        setSaving(false)
      }
    },
    [handleSignOff]
  )

  const handleSignOffNo = useCallback(() => {
    setOpenSignOffConfirm(false)
  }, [])

  const handleSaveFile = useCallback(
    async (f: IFile, isEdit: boolean = false, refresh: boolean = true) => {
      if (wa?.id !== undefined) {
        const file: IWorkAssignmentFile = { ...f, work_assignment: wa.id }
        if (isEdit && file.id !== undefined) {
          const waFile = await fileRepository.edit(file, file.id)
          if (refresh) {
            reloadWa()
          }
          return waFile
        } else {
          const waFile = await fileRepository.add(file)
          if (refresh) {
            reloadWa()
          }
          return waFile
        }
      }
      return null
    },
    [wa]
  )

  const emailLink = useMemo(() => {
    if (wa?.customer?.name !== undefined && wa.account?.name !== undefined) {
      const subject = `Consultant Support for ${wa.identifier} - ${wa.account.name} - ${wa.consultant_due_date}`
      const body = `Hi RiskLogic,
\n\nState your question...\n\n
======================================================
ID:\t\t\t${wa.identifier}
Customer:\t${wa.customer.name}
Account:\t\t${wa.account.name}
Location:\t\t${locationToString(wa.location)}
Due Date:\t${wa.consultant_due_date}
======================================================\n`
      return `mailto:requests@risklogic.com?subject=${subject}&body=${encodeURIComponent(body)}`
    }
  }, [wa])

  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <ViewLoading loading={loading && wa === undefined} />
        <ErrorMessage error={error} />
        <ErrorMessage error={savingError} />
        {wa !== undefined && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems="end" spacing={2}>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Typography variant="h4" onClick={reloadWa} sx={{ cursor: "pointer" }}>
                      <TruncateText text={workAssignmentLocation(wa)} />
                    </Typography>
                  </Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Grid container alignItems="end">
                      <Grid item>
                        <Typography variant="subtitle1">{wa.customer !== null && <TruncateText text={wa.customer.name} />}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle1">{wa.customer !== null && <sup>({customerTypeName(wa.customer)})</sup>}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <Typography variant="subtitle2">{wa.account !== null && <TruncateText text={wa.account.name} />}</Typography>
                  </Grid>
                  <Grid item>{wa.is_consultant_report_late && <Chip label="Late" color="error" />}</Grid>
                  <Grid item xs={isSmall ? 12 : undefined}>
                    <ViewLoading loading={loading || saving} message="Updating..." />
                  </Grid>
                  <Grid item xs />
                  <Grid item>
                    <DictionaryDialog entries={DICTIONARY_ENTRIES} />
                  </Grid>
                  <Grid item>
                    <Button component={Link} href={emailLink}>
                      Contact RLI
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={styles.tabsBox}>
              <Tabs value={tab} onChange={handleTabChange}>
                <Tab label="Overview" />
                {((hasAccepted && wa.progress_report_sent_account === null) || !hasAcceptedOrDeclined) && <Tab label="Files" />}
              </Tabs>
            </Box>
            <TabPanel index={0} value={tab}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <Grid container spacing={2} sx={{ mt: 1, mb: 2 }} alignItems="center">
                    <Grid item xs={12}>
                      <Grid container alignItems="end">
                        <Grid item xs>
                          <Typography variant="h5">Status</Typography>
                        </Grid>
                        <Grid item>
                          <em>
                            <AssignmentStatus wa={wa} />
                          </em>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>1.</Grid>
                    <Grid item xs>
                      Accept / Decline Work Assignment
                    </Grid>
                    <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                      {hasAcceptedOrDeclined ? (
                        <AssignmentResponse wa={wa} small={true} />
                      ) : (
                        <AcceptDeclineButton
                          disabled={wac === null || saving || loading}
                          wa={wa}
                          onAcceptDeclineWa={handleAcceptDeclineWa}
                        />
                      )}
                    </Grid>

                    {hasAccepted && (
                      <>
                        {wa.inspection_type !== "PR" && (
                          <>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>

                            <Grid item>2.</Grid>
                            <Grid item xs>
                              Add Pre-Survey Document to Files
                            </Grid>
                            <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                              {hasAccepted && (
                                <>
                                  {wa.progress_consultant_pre_survey_received === null ? (
                                    <Grid item xs={isSmall ? 12 : undefined}>
                                      {!saving && !loading && (
                                        <Button
                                          onClick={handleSignOff("pre_survey_received")}
                                          fullWidth
                                          variant="contained"
                                          size="small"
                                          disabled={wac === null}
                                        >
                                          Sign Off
                                        </Button>
                                      )}
                                    </Grid>
                                  ) : (
                                    <FormatDate value={wa.progress_consultant_pre_survey_received} />
                                  )}
                                </>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        {wa.inspection_type !== "PR" && (
                          <>
                            <Grid item>3.</Grid>
                            <Grid item xs>
                              Add Visit Date
                            </Grid>
                            <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                              {hasAccepted &&
                                (isComplete ? (
                                  <FormatDate value={wa.visit_date} />
                                ) : (
                                  <Grid container alignItems="center" spacing={2}>
                                    {wa.visit_date !== null && (
                                      <Grid item xs>
                                        <CalendarDownloadButton wa={wa} />
                                      </Grid>
                                    )}
                                    <Grid item xs>
                                      <ChangeDate
                                        allowClear
                                        waId={wa.id}
                                        field="visit_date"
                                        onChange={reloadWa}
                                        title="Add Visit Date"
                                        value={wa.visit_date}
                                      />
                                    </Grid>
                                  </Grid>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </>
                        )}
                        <>
                          <Grid item xs={12} />
                          <Grid item>{wa.inspection_type !== "PR" ? 4 : 2}.</Grid>
                          <Grid item xs>
                            Add Completed Report to Files
                          </Grid>
                          <Grid item xs={isSmall ? 12 : undefined} sx={{ textAlign: "center" }}>
                            {hasAccepted && (
                              <>
                                {wa.progress_consultant_reports_submitted === null ? (
                                  <Grid item xs={isSmall ? 12 : undefined}>
                                    {wa.visit_date !== null || wa.inspection_type === "PR" ? (
                                      <>
                                        <SignOffDialog
                                          open={openSignOffConfirm}
                                          workAssignmentConsultant={wac}
                                          onNo={handleSignOffNo}
                                          onYes={handleSignOffYes}
                                        />
                                        <Button
                                          onClick={handleOpenSignOffConfirm}
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          disabled={wac === null || saving || loading}
                                        >
                                          Sign Off
                                        </Button>
                                      </>
                                    ) : (
                                      <em>Visit Date Required</em>
                                    )}
                                  </Grid>
                                ) : (
                                  <FormatDate value={wa.progress_consultant_reports_submitted} />
                                )}
                              </>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                          {!isComplete && (
                            <Grid item xs={12}>
                              {wa.progress_consultant_docs_ready !== null ? (
                                <Alert severity="success">Site files are prepared and ready to access.</Alert>
                              ) : (
                                <Alert severity="error">
                                  Site files are not ready. You will receive an email notification when the RLI Administrative Team loads
                                  these documents.
                                </Alert>
                              )}
                            </Grid>
                          )}
                        </>
                      </>
                    )}

                    {((hasAccepted && !isComplete) || !hasAcceptedOrDeclined) && (
                      <Grid item xs={12}>
                        {wa?.service_instructions_url !== null && wa?.service_instructions_url !== "" && (
                          <Alert
                            severity="info"
                            sx={{ mt: 1, mb: 1 }}
                            action={
                              <Button component={Link} target="_blank" href={wa.service_instructions_url} sx={{ whiteSpace: "nowrap" }}>
                                Service Instructions
                              </Button>
                            }
                          >
                            Please review the Account Service Instructions before starting inspection.{" "}
                            {wa.service_instructions_change_date !== null && (
                              <>
                                Last updated <FormatDate value={wa.service_instructions_change_date} />.
                              </>
                            )}
                          </Alert>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <Typography variant="h5">Details</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: -2 }}>
                      <ViewProperty label="ID">
                        <Box sx={{ textAlign: "right" }}>{wa.identifier}</Box>
                      </ViewProperty>
                      <ViewProperty label="Consultant Fee">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatNumber value={wac?.amount} suffixUnits={wac?.hourly_rate === true ? "/hr" : ""} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Priority">
                        <Box sx={{ textAlign: "right" }}>{wa.priority_name}</Box>
                      </ViewProperty>
                      <ViewProperty label="Visit Date" show={wa.inspection_type !== "PR"}>
                        <Box sx={{ textAlign: "right" }}>
                          <FormatDate value={wa.visit_date} nullText="Not Scheduled" />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Due Date">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatDate value={wa.consultant_due_date} />
                        </Box>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ mt: -2 }}>
                      <ViewProperty label="Business Interruption">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatNumber value={wa.business_interruption} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Property Damage">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatNumber value={wa.property_damage} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="TIV">
                        <Box sx={{ textAlign: "right" }}>
                          <FormatNumber value={wa.total_insured_value} />
                        </Box>
                      </ViewProperty>
                      <ViewProperty label="Inspection Type">
                        <Box sx={{ textAlign: "right" }}>{wa.inspection_type_name !== "" ? wa.inspection_type_name : <>Not Set</>}</Box>
                      </ViewProperty>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                        <Grid item xs>
                          <strong>Report Format</strong>
                        </Grid>
                        {wa.report_format !== null && wa.report_format.file.exists !== false ? (
                          <Grid item>
                            <Button
                              startIcon={<DownloadIcon />}
                              variant="outlined"
                              size="small"
                              target="__blank"
                              href={wa.report_format.file.read_url}
                            >
                              {wa.report_format?.name}
                            </Button>
                          </Grid>
                        ) : (
                          <Grid item>{wa.report_format !== null ? wa.report_format?.name : <>Not Set</>}</Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={6}>
                      {wa.executive !== undefined && wa.executive !== null && <ExecutiveCard executive={wa.executive} />}
                      {wa.technical_admin !== undefined && wa.technical_admin !== null && (
                        <Box sx={{ mt: 2 }}>
                          <ExecutiveCard executive={wa.technical_admin} label="Technical Admin" />
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {wa.location.contacts?.map(contact => (
                        <Box sx={{ mb: 2 }} key={contact.id}>
                          <ContactCard contact={contact} prefixLabel="Location" />
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                  {wa.contact_notes !== null && wa.contact_notes !== "" && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Contact Notes</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: wa.contact_notes }} />
                      </Grid>
                    </Grid>
                  )}
                  {wa.notes_consultant !== null && wa.notes_consultant !== "" && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <Typography variant="h5">Notes</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <div dangerouslySetInnerHTML={{ __html: wa.notes_consultant }} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} lg={6}>
                  <WorkAssignmentLocation location={wa.location} />
                  <WorkAssignmentMap location={wa.location} />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={tab}>
              <FilesEditor
                files={wa.files?.filter(f => f.id)}
                onChange={reloadWa}
                onSaveFile={handleSaveFile}
                haveReportsSubmitted={wa.progress_report_sent_account !== null}
                hasAccepted={hasAccepted}
                location={wa.location}
              />
            </TabPanel>
          </>
        )}
      </Box>
    </WorkflowPage>
  )
}

export default ViewPage

import { IAccount } from "./IAccount"
import { IFile } from "./IFile"

/**
 * Test if an object is a customer.
 *
 * @param {any} obj the object to test.
 * @returns {boolean} true if it is a customer.
 */
export const isCustomer = (obj: any): obj is ICustomer => true

export const CUSTOMER_ENDPOINT = "customers"

// These values are duplicated on the server. They shouldn't change much if at all, so duplication should be fine.
// Be sure to reflect any changes on the server.
export const CUSTOMER_TYPES = [
  { key: "I", value: "Insurance" },
  { key: "B", value: "Broker" },
  { key: "D", value: "Direct" },
]

export const DEFAULT_CUSTOMER_TYPE = CUSTOMER_TYPES[0]

/**
 * Get the customer type name.
 *
 * @param {ICustomer} customer to get the type from.
 * @returns {string} then of the type or the default type name.
 */
export const customerTypeName = (customer: ICustomer): string => {
  for (const customerType of CUSTOMER_TYPES) {
    if (customerType.key === customer.customer_type) {
      return customerType.value
    }
  }
  return DEFAULT_CUSTOMER_TYPE.value
}

export interface ICustomer {
  id: number
  identifier: string
  customer_type: string
  name: string
  notes: string
  since: string
  accounts?: IAccount[]
  accounts_count: number
  files: IFile[]
  created?: string
  updated?: string
}

import * as React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../app/shared/components/PrivateRoute"
import LoginPage from "../app/pages/auth/LoginPage"
import DefaultLayout from "../app/shared/layouts/DefaultLayout"

import ProfileView from "../app/pages/profile/ViewPage"

import WorkAssignmentsIndex from "../app/pages/work_assignments/IndexPage"
import WorkAssignmentsView from "../app/pages/work_assignments/ViewPage"

import ExpenseReportsIndex from "../app/pages/expense_reports/IndexPage"
import ExpenseReportsView from "../app/pages/expense_reports/ViewPage"

import TechReviewsIndex from "../app/pages/tech_reviews/IndexPage"
import TechReviewsView from "../app/pages/tech_reviews/ViewPage"

import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import NotFound from "./404"
import { CssBaseline } from "@mui/material"
import {
  EXPENSE_REPORTS_URL,
  EXPENSE_REPORTS_VIEW_URL,
  LOGIN_URL,
  PROFILE_URL,
  TECH_REVIEWS_URL,
  TECH_REVIEWS_VIEW_URL,
  WORK_ASSIGNMENT_VIEW_URL,
  WORK_ASSIGNMENTS_URL,
} from "../app/config/urls"
import LoginLayout from "../app/shared/layouts/LoginLayout"
import { Provider, shallowEqual, useSelector } from "react-redux"
import store, { DashboardStore } from "../app/store"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { ACCESS_CONSULTANT_EXPENSE_REPORT_GROUP, ACCESS_TECH_REVIEWER_GROUP } from "../app/config/permissions"
import { ISiteMeta } from "../app/shared/models/ISiteMeta"

const GATSBY_GOOGLE_AUTH_TOKEN: string = process.env.GATSBY_GOOGLE_AUTH_TOKEN === undefined ? "" : process.env.GATSBY_GOOGLE_AUTH_TOKEN

interface AppProps {
  data: ISiteMeta | undefined
}

/**
 * Main app component. Handles routing.
 *
 * @param {AppProps} props see AppProps for details.
 * @returns {React.FC<AppProps>} the app component.
 */
const App: React.FC<AppProps> = (props: AppProps): React.ReactElement => {
  const { data } = props
  const { title } = useSelector((state: DashboardStore) => ({ ...state.settings }), shallowEqual)

  return (
    <>
      <Helmet>
        <title>
          {data?.site.siteMetadata.title} | {title}
        </title>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
      </Helmet>
      <CssBaseline />
      <Router>
        <LoginLayout path="/" title="Login" component={LoginPage} />
        <LoginLayout path={LOGIN_URL} title="Login" component={LoginPage} />
        <PrivateRoute path={`${PROFILE_URL}`} component={() => <DefaultLayout title="Profile" component={ProfileView} />} />
        <PrivateRoute path={WORK_ASSIGNMENTS_URL} component={() => <DefaultLayout title="Inspection" component={WorkAssignmentsIndex} />} />
        <PrivateRoute
          path={`${WORK_ASSIGNMENT_VIEW_URL}/:id`}
          component={() => <DefaultLayout title="Inspections" component={WorkAssignmentsView} />}
        />
        <PrivateRoute
          path={EXPENSE_REPORTS_URL}
          groupNames={[ACCESS_CONSULTANT_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Expense Reports" component={ExpenseReportsIndex} />}
        />
        <PrivateRoute
          path={`${EXPENSE_REPORTS_VIEW_URL}/:id`}
          groupNames={[ACCESS_CONSULTANT_EXPENSE_REPORT_GROUP]}
          component={() => <DefaultLayout title="Expense Report" component={ExpenseReportsView} />}
        />
        <PrivateRoute
          path={TECH_REVIEWS_URL}
          groupNames={[ACCESS_TECH_REVIEWER_GROUP]}
          component={() => <DefaultLayout title="Tech Reviews" component={TechReviewsIndex} />}
        />
        <PrivateRoute
          path={`${TECH_REVIEWS_VIEW_URL}/:id`}
          groupNames={[ACCESS_TECH_REVIEWER_GROUP]}
          component={() => <DefaultLayout title="Tech Review" component={TechReviewsView} />}
        />
        <NotFound default data={data} />
      </Router>
    </>
  )
}

/**
 * Wraps the app with a redux store.
 *
 * @param {AppProps} props see AppProps for details.
 * @returns {React.FC} the app wrapper component.
 */
const AppWrapper: React.FC<AppProps> = (props: AppProps): React.ReactElement => {
  return (
    <>
      <GoogleOAuthProvider clientId={GATSBY_GOOGLE_AUTH_TOKEN}>
        <Provider store={store.store}>
          <App {...props} />
        </Provider>
      </GoogleOAuthProvider>
    </>
  )
}

export default AppWrapper

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        version
      }
    }
  }
`

import * as React from "react"
import { DATE_FORMAT } from "../../../config/config"
import Moment from "react-moment"
import moment from "moment"

interface IProps {
  value: string | moment.Moment | null | undefined
  nullText?: string
  format?: string
}

/**
 * Format a date given a string.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC} a formatted date.
 */
const FormatDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { value, nullText = "Not Set", format = DATE_FORMAT } = props

  return <>{value !== null && value !== undefined ? <Moment format={format}>{value}</Moment> : <>{nullText}</>}</>
}

export default FormatDate

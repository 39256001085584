import * as React from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import DialogControls from "./DialogControls"

interface IProps {
  open: boolean
  title?: string
  message: React.ReactElement
  onNo: () => void
  onYes: () => void
}

/**
 * Creates an alert dialog box to let the user know something is about to happen.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the alert dialog.
 */
const AlertDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { open, onNo, onYes, title = "Confirm", message } = props

  return (
    <>
      <Dialog open={open} onClose={onNo}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogControls onCancel={onNo} onSave={onYes} buttonLabel="Yes, I'm Sure" />
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog

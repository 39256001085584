import { Skeleton, TableCell, TableRow } from "@mui/material"
import * as React from "react"
import { useMemo } from "react"

interface IProps {
  loading: boolean
  columns: number
  rows: number
}

/**
 * Displays a loading indicator for a table.
 *
 * @param {IProps} props is IProps for details.
 * @returns {React.FC<IProps>} the table loading component.
 */
const TableLoading: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { loading, columns, rows } = props

  const rowIndex = useMemo(() => {
    return Array.from({ length: rows }, (x, i) => i)
  }, [])

  const columnIndex = useMemo(() => {
    return Array.from({ length: columns }, (x, i) => i)
  }, [])

  return loading ? (
    <>
      {rowIndex.map(i => (
        <TableRow key={i}>
          {columnIndex.map(j => (
            <TableCell key={j}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  ) : (
    <></>
  )
}

export default TableLoading

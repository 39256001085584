import { Box, Grid, Pagination } from "@mui/material"
import * as React from "react"
import { ChangeEvent } from "react"

interface IProps {
  count: number
  page: number
  total?: number | undefined
  handlePaging: (_e: ChangeEvent<unknown>, page: number) => void
  size?: "small" | "medium" | "large"
}

const numFormatOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }

/**
 * A general purpose component for paging data.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the table paging component
 */
const TablePaging: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { count, page, total, handlePaging, size = "medium" } = props

  return total !== undefined && total > 0 ? (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        <Box sx={{ p: 2 }}>
          <Pagination
            count={count}
            page={page}
            siblingCount={size === "large" ? 0 : undefined}
            boundaryCount={size === "large" ? 1 : undefined}
            onChange={handlePaging}
            size={size}
          />
        </Box>
      </Grid>
      <Grid item xs={size === "large" ? 12 : undefined}>
        <Box sx={{ p: 2, mr: 1 }}>
          {size !== "small" && <>Results: </>}
          {total.toLocaleString(undefined, numFormatOptions)}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default TablePaging

import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { IPagedResults } from "../models/IPagedResults"
import { IPaging } from "../models/IPaging"
import { useLocation } from "@reach/router"
import { CONNECTION_ERROR, IConnectionError } from "../models/IConnectionError"
import { IOrdering } from "../models/IOrdering"
import { IFilter } from "../models/IFilter"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { DashboardStore } from "../../store"
import { operations } from "../../store/settings"

interface IUseApiPagedResultsResponse<T> {
  call: () => void
  loading: boolean
  count: number
  page: number | undefined
  ordering?: IOrdering
  filters?: IFilter[]
  error: IConnectionError | undefined
  limit: number
  data: IPagedResults<T> | undefined
  handlePaging: (_e: ChangeEvent<unknown>, page: number) => void
  handleOrdering: (ordering: IOrdering) => void
  handleFilter: (filters: IFilter[]) => void
}

export interface IUseApiPagedResultsProps<T> {
  apiFunction: (paging?: IPaging) => Promise<IPagedResults<T>>
  limit?: number
  dontCallOnMount?: boolean
  pathname?: string
}

/**
 * This hook will do all the heavy lifting of paging api results.
 *
 * @param {IUseApiPagedResultsProps} props see IUseApiEditProps<T> for details.
 * @returns {IUseApiPagedResultsResponse} edit state
 */
export const useApiPaged = <T>(props: IUseApiPagedResultsProps<T>): IUseApiPagedResultsResponse<T> => {
  const location = useLocation()
  const { apiFunction, dontCallOnMount, limit = 10, pathname = location.pathname } = props
  const pagingMap = useSelector((state: DashboardStore) => state.settings.paging, shallowEqual) as { string: IPaging }

  const dispatch = useDispatch()

  const paging = pagingMap !== null && pathname in pagingMap ? ((pagingMap as any)[pathname] as IPaging) : null

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IPagedResults<T> | undefined>()
  const [error, setError] = useState<IConnectionError | undefined>()

  const [didFirstLoad, setDidFirstLoad] = useState<boolean>()

  const count = useMemo(() => (data?.count !== undefined ? Math.ceil(data.count / limit) : 0), [data, limit])

  const handlePaging = useCallback(
    async (_e: ChangeEvent<any>, page: number) => {
      dispatch(operations.setPage(pathname, page, (page - 1) * limit))
    },
    [pathname]
  )

  const handleOrdering = useCallback(
    async (ordering: IOrdering) => {
      dispatch(operations.setOrdering(pathname, ordering))
    },
    [pathname]
  )

  const handleFilter = useCallback(
    async (filters: IFilter[]) => {
      dispatch(operations.setFilters(pathname, filters))
    },
    [pathname]
  )

  const call = useCallback(() => {
    const thisPaging: IPaging = { limit, ...paging }
    setLoading(true)
    apiFunction(thisPaging)
      .then(results => {
        setData(results)
        setError(undefined)
      })
      .catch(reason => {
        setData(undefined)
        if (reason?.response !== undefined) {
          setError(reason.response)
        } else {
          setError(CONNECTION_ERROR)
        }
      })
      .finally(() => setLoading(false))
  }, [apiFunction, paging, limit])

  useEffect(() => {
    if (didFirstLoad === false && !loading && (dontCallOnMount === undefined || !dontCallOnMount)) {
      setDidFirstLoad(true)
      call()
    }
  }, [call, dontCallOnMount, didFirstLoad])

  useEffect(() => {
    setDidFirstLoad(false)
  }, [paging, limit])

  return {
    call,
    loading,
    error,
    data,
    count,
    page: paging?.page,
    ordering: paging?.ordering,
    filters: paging?.filters,
    limit,
    handlePaging,
    handleOrdering,
    handleFilter,
  }
}

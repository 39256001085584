import * as React from "react"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { IPagedResults } from "../../shared/models/IPagedResults"
import { EXPENSE_REPORTS_ENDPOINT, IExpenseReport } from "../../shared/models/IExpenseReport"
import { IPaging } from "../../shared/models/IPaging"
import { CONNECTION_ERROR, IConnectionError } from "../../shared/models/IConnectionError"
import { RestRepository } from "../../shared/repositories/RestRepository"
import ErrorMessage from "../../shared/components/ErrorMessage"
import { Alert, Box, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import TableLoading from "../../shared/components/TableLoading"
import TableRowSelect from "../../shared/components/TableRowSelect"
import FormatDate from "../../shared/components/format/FormatDate"
import FormatNumber from "../../shared/components/format/FormatNumber"
import TablePaging from "../../shared/components/TablePaging"
import { EXPENSE_REPORTS_VIEW_URL } from "../../config/urls"
import { navigate } from "gatsby"
import AddExpenseReportDialog from "./components/AddExpenseReportDialog"
import WorkflowPage from "../../shared/components/WorkflowPage"
import AdpPayPeriodDisplay from "./components/AdpPayPeriodDisplay"
import PayPeriodDisplay from "./components/PayPeriodDisplay"
import { IAdminHours, PROFILES_ENDPOINT } from "../../shared/models/IProfile"
import useAuth from "../../shared/hooks/useAuth"

const repository = new RestRepository<IExpenseReport>(EXPENSE_REPORTS_ENDPOINT)
const profileRepository = new RestRepository<IAdminHours>(PROFILES_ENDPOINT)

const limit = 10

/**
 * A page to list all expense reports.
 *
 * @returns {React.FC} the expense reports list page.
 */
const IndexPage: React.FC = (): React.ReactElement => {
  const [page, setPage] = useState(1)
  const [expenseReports, setExpenseReports] = useState<IPagedResults<IExpenseReport> | null>(null)
  const [adminHours, setAdminHours] = useState<IAdminHours | null>(null)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAuth()

  const handleSelected = useCallback(async (expenseReport: IExpenseReport) => {
    await navigate(`${EXPENSE_REPORTS_VIEW_URL}/${expenseReport.id}`)
  }, [])

  const handlePaging = useCallback((_e: ChangeEvent<unknown> | null, page1: number) => {
    setPage(page1)
    setExpenseReports(null)
  }, [])

  const loadExpenseReports = useCallback(async () => {
    try {
      setError(null)
      setLoading(true)
      const paging: IPaging = {
        limit,
        offset: limit * (page - 1)
      }
      const results = await repository.findAll(paging)
      setExpenseReports(results)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response as IConnectionError)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [page])

  const loadAdminHours = useCallback(async () => {
    if (currentUser?.user.profile.id !== undefined) {
      try {
        setError(null)
        setLoading(true)
        const results = await profileRepository.action(currentUser.user.profile.id, "adp_admin_time")
        setAdminHours(results)
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError(reason.response as IConnectionError)
        } else {
          setError(CONNECTION_ERROR)
        }
      }
      setLoading(false)
    }
  }, [currentUser])

  useEffect(() => {
    if (expenseReports === null) {
      void (async () => {
        await loadExpenseReports()
      })()
    }
  }, [expenseReports, loadExpenseReports])

  useEffect(() => {
    if (adminHours === null) {
      void (async () => {
        await loadAdminHours()
      })()
    }
  }, [adminHours, loadAdminHours])

  return (
    <WorkflowPage>
      {error !== undefined && (
        <Box sx={{ m: 2 }}>
          <ErrorMessage error={error} />
        </Box>
      )}
      <AddExpenseReportDialog onChange={loadExpenseReports} />
      {adminHours !== null && (adminHours.total_admin_hours > 0 || adminHours.total_pto > 0) &&
        <Box sx={{ m: 2, mt: 0 }}>
          <Alert color="info">
            <Grid container spacing={1}>
              <Grid item>
                <strong>Current Year:</strong> {adminHours.year}
              </Grid>
              <Grid item>
                <strong>Total Admin Hours Logged YTD:</strong> {adminHours.total_admin_hours}
              </Grid>
              <Grid item>
                <strong>Total PTO Hours Logged YTD:</strong> {adminHours.total_pto}
              </Grid>
            </Grid>
          </Alert>
        </Box>
      }
      <Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Paid Period</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Amount</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Hours</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Report Ready</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableLoading loading={loading} columns={9} rows={limit} />
              {!loading &&
                expenseReports?.results.map(expenseReport => (
                  <TableRowSelect key={expenseReport.id} item={expenseReport} onSelected={handleSelected}>
                    <TableCell>{expenseReport.id}</TableCell>
                    <TableCell>
                      {expenseReport.pay_period !== null ? (
                        <PayPeriodDisplay payPeriod={expenseReport.pay_period} />
                      ) : (
                        <AdpPayPeriodDisplay adpPayPeriod={expenseReport.adp_pay_period} />
                      )}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <FormatNumber twoDecimalPlaces value={expenseReport.total_amount} />
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      <FormatNumber twoDecimalPlaces value={expenseReport.total_admin_hours} prefixUnits={false} />
                    </TableCell>
                    <TableCell>
                      <FormatDate value={expenseReport.report_ready} />
                    </TableCell>
                    <TableCell>
                      <FormatDate value={expenseReport.created} />
                    </TableCell>
                    <TableCell>
                      <FormatDate value={expenseReport.updated} />
                    </TableCell>
                  </TableRowSelect>
                ))}
            </TableBody>
          </Table>

          {!loading && (expenseReports === null || expenseReports.count === 0) && (
            <Box sx={{ p: 2 }}>
              <Alert color="info">No expense reports found.</Alert>
            </Box>
          )}
        </TableContainer>
      </Box>
      <Divider />
      {expenseReports !== null && (
        <TablePaging count={Math.ceil(expenseReports.count / limit)} total={expenseReports.count} page={page}
                     handlePaging={handlePaging} />
      )}
    </WorkflowPage>
  )
}

export default IndexPage

import * as React from "react"
import { useCallback, useState } from "react"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"

interface IProps {
  disabled: boolean
  onAcceptDeclineWa: (action?: string) => Promise<void>
}

/**
 * Button to accept or decline tech reviewing for a work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the accept / decline buttons.
 */
const AcceptDeclineTechReviewButton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { disabled, onAcceptDeclineWa } = props
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleAccept = useCallback(async () => {
    await onAcceptDeclineWa()
  }, [])

  const handleOpenDecline = useCallback(() => {
    setOpen(true)
  }, [])

  const handleSave = useCallback(async () => {
    await onAcceptDeclineWa("decline")
    setOpen(false)
  }, [])

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Button onClick={handleAccept} color="success" variant="contained" fullWidth size="small" disabled={disabled}>
            Accept
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleOpenDecline} color="error" variant="contained" size="small" fullWidth disabled={disabled}>
            Decline
          </Button>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Decline Tech Review</DialogTitle>
        <DialogContent>
          <Box>
            <p>Are you sure you want to decline this work assignment?</p>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            <Grid item xs>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={handleSave} color="error" variant="contained">
                Decline
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AcceptDeclineTechReviewButton

import { ISpecialty } from "./ISpecialty"
import { green, red, orange, yellow, blue } from "@mui/material/colors"
import { IConsultant } from "./IConsultant"

export const CONSULTANT_SPECIALTY_ENDPOINT = "consultant_specialties"

export interface IConsultantSpecialty {
  id: number
  score: number
  note: string
  specialty: ISpecialty | number
  specialty_id: number
  consultant: IConsultant | number
}

export const SpecialtyScoreColor = [
  green[400],
  blue[400],
  yellow[400],
  orange[400],
  red[400]
]

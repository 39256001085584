import { SpecialtyScoreColor } from "../models/IConsultantSpecialty"
import { IWorkAssignment } from "../models/IWorkAssignment"
import { red } from "@mui/material/colors"
import { linearProgressClasses, Theme } from "@mui/material"

export const styles = {
  progress: {
    height: 10,
    borderRadius: 5,
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
  },
  locations: {
    list: {
      paddingTop: 0,
      "& li": {
        paddingTop: 0,
        "& div": {
          marginTop: 0,
        },
      },
    },
  },
  marginTopPaper: {
    padding: 2,
    marginTop: 20,
    color: "text.secondary",
  },
  specialty: {
    avatar: (score: number) => (theme: Theme) => ({
      bgcolor: theme.palette.common.white,
      border: `1px solid ${SpecialtyScoreColor[score - 1]}`,
      color: theme.palette.common.black,
    }),
    chip: (score: number) => ({
      marginRight: 1,
      marginBottom: 1,
      borderColor: SpecialtyScoreColor[score - 1],
    }),
  },
  languages: {
    chip: {
      marginRight: 1,
      marginBottom: 1,
    },
  },
  consultant: {
    identifier: {
      marginLeft: 3,
      marginRight: 3,
    },
  },
  index: {
    search: {
      padding: 2,
    },
    results: {
      overflow: "hidden",
    },
  },
  page: {
    padding: 2,
  },
  viewProperty: {
    marginTop: 0.3,
    marginBottom: 0.3,
  },
  tabsBox: {
    marginTop: 1,
    borderBottom: 1,
    borderColor: "divider",
  },
  tableSelect: {
    cursor: "pointer",
  },
  accordion: (theme: Theme) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }),
  consultantReportIsLate: (wa: IWorkAssignment) => ({
    color: wa.is_consultant_report_late ? red[500] : "inherit",
  }),
  workAssignmentRow: (wa: IWorkAssignment) => ({
    background: wa.is_report_sent_account_late ? red[500] + "33" : "inherit",
  }),
}

import {
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import * as React from "react"
import CloseIcon from "@mui/icons-material/Close"
import { ChangeEvent, useCallback, useState } from "react"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { IFile } from "../../../shared/models/IFile"
import useAzureStorage from "../../../shared/hooks/useAzureStorage"
import FormatDate from "../../../shared/components/format/FormatDate"
import FileDownloadButton from "../../../shared/components/FileDownloadButton"
import { ILocation, locationToString } from "../../../shared/models/ILocation"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import useContentHeight from "../../../shared/hooks/useContentHeight"
import TruncateText from "../../../shared/components/TruncateText"
import prettyBytes from "pretty-bytes"
import DialogControls from "../../../shared/components/DialogControls"
import ViewLoading from "../../../shared/components/ViewLoading"

interface IProps {
  files: IFile[] | undefined
  onChange: () => void
  hasAccepted: boolean
  haveReportsSubmitted: boolean
  onSaveFile: (file: IFile, isEdit?: boolean, refresh?: boolean) => Promise<IFile | null>
  location?: ILocation
}

/**
 * Allows the editing of consultant files.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the files editor.
 */
const FilesEditor: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { files, onChange, hasAccepted, haveReportsSubmitted, onSaveFile, location } = props
  const [open, setOpen] = useState(false)
  const [openPreview, setPreviewOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [selectedFile, setSelectedFile] = useState<IFile | null>(null)
  const { uploading, progress, uploadFile } = useAzureStorage()
  const [inputKey, setInputKey] = useState(Math.random().toString(36))
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const [fileSelected, setFileSelected] = useState<File | null>(null)
  const [name, setName] = useState("")
  const [ext, setExt] = useState("")

  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  const height = useContentHeight(-15)

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files.length > 0) {
      const file = e.target.files[0]
      const filename = file.name
      let ext = filename.split(".").pop()
      ext = ext === undefined ? "" : ext
      const name = filename.replace(`.${ext}`, "")
      setName(name)
      setExt(ext === undefined || ext === name ? "" : ext)
      setFileSelected(file)
    }
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    setSelectedFile(null)
    setFileSelected(null)
    setSavingError(null)
  }, [])

  const handleClosePreview = useCallback(() => {
    setPreviewOpen(false)
  }, [])

  const handleOpenPreview = useCallback(
    (file: IFile) => () => {
      setSelectedFile(file)
      setPreviewOpen(true)
    },
    []
  )

  const handleEdit = useCallback(
    (file: IFile) => () => {
      setName(file.name)
      setExt(file.ext)
      setSelectedFile(file)
      setOpen(true)
    },
    []
  )

  const handleAdd = useCallback(() => {
    setOpen(true)
    setSelectedFile(null)
    setName("")
    setExt("")
    setFileSelected(null)
  }, [])

  const handleSave = useCallback(async () => {
    try {
      setSavingError(null)
      setSaving(true)
      let savedFile: IFile | null
      if (selectedFile !== null) {
        const theFile: IFile = { id: selectedFile.id, name, ext }
        savedFile = await onSaveFile(theFile, true)
      } else {
        const theFile: IFile = { name, ext }
        savedFile = await onSaveFile(theFile)
      }
      if (savedFile?.file !== undefined) {
        await uploadFile(savedFile.file, fileSelected)
      }
      setInputKey(Math.random().toString(36))
      onChange()
      handleClose()
    } catch (reason: any) {
      if (reason.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
  }, [name, ext, selectedFile, fileSelected])

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm>
          <Breadcrumbs aria-label="breadcrumb">
            {location !== undefined && <Typography color="text.primary">{locationToString(location)}</Typography>}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={isSmall ? 12 : undefined}>
          {hasAccepted && !haveReportsSubmitted && (
            <Box sx={{ textAlign: "right" }}>
              <Button variant="contained" onClick={handleAdd}>
                Add File
              </Button>
            </Box>
          )}
          <Dialog onClose={uploading || saving ? () => {} : handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>{selectedFile !== null ? "Edit" : "Add"} File</DialogTitle>
            <DialogContent>
              <ErrorMessage error={savingError} />

              {!uploading && !saving ? (
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12}>
                    <input type="file" onChange={handleFileChange} key={inputKey !== "" ? inputKey : ""} />
                  </Grid>
                  {(fileSelected !== null || selectedFile !== null) && (
                    <>
                      <Grid item xs={8}>
                        <TextField fullWidth label="Name" name="name" value={name} onChange={e => setName(e.target.value)} />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField fullWidth label="Ext" name="ext" value={ext} onChange={e => setExt(e.target.value)} />
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <ViewLoading loading={true} message="Saving" />
                    </Grid>
                    <Grid item>
                      <TruncateText text={`${name}.${ext}`} placement="bottom" num={50} />
                    </Grid>
                  </Grid>
                  {progress !== null && (
                    <Grid item xs={12}>
                      <Box sx={{ pt: 2 }}>
                        <LinearProgress variant="determinate" value={progress} />
                      </Box>
                    </Grid>
                  )}
                </>
              )}
            </DialogContent>
            <DialogActions>
              {!uploading && !saving && (
                <DialogControls
                  onCancel={handleClose}
                  onSave={handleSave}
                  loading={uploading || saving}
                  disabled={(fileSelected === null && selectedFile === null) || uploading || saving}
                />
              )}
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs>
          {isSmall ? (
            <List>
              {files?.map(file => (
                <React.Fragment key={file.id}>
                  <ListItem sx={{ mt: 1, mb: 1 }}>
                    <ListItemText
                      primary={
                        <Typography sx={{ display: "inline" }} variant="h6">
                          {file.name}
                        </Typography>
                      }
                      secondary={
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {file.updated !== undefined && <FormatDate value={file.updated} />}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            {hasAccepted && !haveReportsSubmitted && file.file?.exists !== false && (
                              <Button onClick={handleEdit(file)} fullWidth variant="outlined">
                                Update
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs>
                            <FileDownloadButton file={file} fullWidth size="medium" />
                          </Grid>
                          <Grid item xs>
                            <Button fullWidth onClick={handleOpenPreview(file)} variant="outlined">
                              View
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Ext</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Updated</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files?.map(file => (
                    <TableRow key={file.id}>
                      <TableCell>
                        <TruncateText text={file.name} placement="left-start" num={50} />
                      </TableCell>
                      <TableCell>{file.ext}</TableCell>
                      <TableCell>{file.file?.size !== undefined && prettyBytes(file.file.size)}</TableCell>
                      <TableCell>{file.updated !== undefined && <FormatDate value={file.updated} />}</TableCell>
                      <TableCell sx={{ textAlign: "right" }}>
                        {hasAccepted && !haveReportsSubmitted && file.file?.write_url !== undefined && file.file.write_url !== null && (
                          <Button size="small" sx={{ mr: 2 }} onClick={handleEdit(file)} variant="outlined">
                            Update
                          </Button>
                        )}
                        {hasAccepted && <FileDownloadButton file={file} />}
                        <Button size="small" sx={{ ml: 2 }} onClick={handleOpenPreview(file)} variant="outlined">
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  {(files === undefined || files.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={6}>No files found.</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
        <Grid item>
          <Dialog
            fullScreen={isSmall}
            fullWidth={!isSmall}
            maxWidth={!isSmall ? "xl" : undefined}
            open={openPreview}
            onClose={handleClosePreview}
          >
            {isSmall ? (
              <AppBar sx={{ position: "relative" }}>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={handleClosePreview} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {selectedFile?.name}
                  </Typography>
                </Toolbar>
              </AppBar>
            ) : (
              <DialogTitle>{selectedFile?.name}</DialogTitle>
            )}
            <DialogContent
              sx={{
                pb: 0,
                "& a#pdf-download": { display: "none" },
                "& #header-bar": { display: "none" },
                "& #proxy-renderer": { overflowY: "hidden" },
              }}
            >
              {selectedFile?.file?.read_url !== undefined && (
                <>
                  <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    style={{ height }}
                    config={{ header: { disableFileName: true } }}
                    documents={[{ uri: selectedFile.file.read_url }]}
                  />
                  <Box sx={{ p: 2, mt: -4, position: "relative", backgroundColor: "#ffffff" }}></Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClosePreview}>Close</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </>
  )
}

export default FilesEditor

import * as React from "react"
import { useCallback, useMemo, useState } from "react"
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import { IWorkAssignmentConsultant } from "../../../shared/models/IWorkAssignmentConsultant"

interface IProps {
  open: boolean
  workAssignmentConsultant: IWorkAssignmentConsultant | null
  onNo: () => void
  onYes: (hours: number) => Promise<void>
}

/**
 * Displays the sign-off dialog for the consultant when they are finished with the work assignment.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the sign off dialog.
 */
const SignOffDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { open, workAssignmentConsultant, onNo, onYes } = props

  const [hours, setHours] = useState<string>("0.0")

  const handleHours = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setHours(event.target.value)
  }, [])

  const handleSubmit = useCallback(async () => {
    await onYes(Number(hours))
  }, [onYes, hours])

  const isHourly = useMemo((): boolean => {
    return workAssignmentConsultant?.hourly_rate === true
  }, [workAssignmentConsultant])

  return (
    <>
      <Dialog open={open} onClose={onNo}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="warning">Before signing off, have you uploaded your report or other files?</Alert>
            </Grid>
            {isHourly && (
              <>
                <Grid item xs={8}>
                  This inspection is paid at an hourly rate of{" "}
                  <strong>
                    <FormatNumber value={workAssignmentConsultant?.amount} display="inline" suffixUnits={"/hr"} />
                  </strong>
                  . How many hours did you work?
                </Grid>
                <Grid item xs={4}>
                  <TextField value={hours} onChange={handleHours} label="Hours Worked" />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onNo}>Cancel</Button>
          <Button onClick={handleSubmit} autoFocus disabled={isHourly && Number(hours) === 0}>
            Yes, I&apos;m Sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SignOffDialog

import * as React from "react"
import { useCallback } from "react"
import { Box, Divider, Grid, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, useMediaQuery, useTheme } from "@mui/material"
import { Dashboard } from "@mui/icons-material"
import { Link, useLocation } from "@reach/router"
import { WORK_ASSIGNMENTS_URL } from "../../config/urls"
import { graphql, useStaticQuery } from "gatsby"

const GATSBY_TOP_MENU = process.env.GATSBY_TOP_MENU === "true"

interface IProps {
  children: any
}

/**
 * Wrapper page for workflow.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the wrapper page.
 */
const WorkflowPage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children } = props
  const data = useStaticQuery(
    graphql`
      query WorkflowPageQuery {
        site {
          siteMetadata {
            title
            version
            company
          }
        }
      }
    `
  )

  const location = useLocation()
  const theme1 = useTheme()
  const isSmall = useMediaQuery(theme1.breakpoints.down("md"))

  const isSelected = useCallback((path: string) => location.pathname.startsWith(path), [location])

  return (
    <Box sx={{ m: isSmall ? 0 : 2 }}>
      <Grid container spacing={2}>
        {!GATSBY_TOP_MENU && (
          <Grid item>
            <Paper sx={{ width: 320, maxWidth: "100%" }}>
              <MenuList>
                <MenuItem component={Link} to={WORK_ASSIGNMENTS_URL} selected={isSelected(WORK_ASSIGNMENTS_URL)}>
                  <ListItemIcon>
                    <Dashboard fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Dashboard</ListItemText>
                </MenuItem>
                <Divider />
              </MenuList>
            </Paper>
          </Grid>
        )}
        <Grid item xs>
          {isSmall ? children : <Paper>{children}</Paper>}
          <Box sx={{ mt: 1, p: 2, textAlign: "right" }}>
            © {data.site.siteMetadata.company} {new Date().getFullYear()}
            <Box sx={{ ml: 1 }} component="small">
              v{data.site.siteMetadata.version}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default WorkflowPage

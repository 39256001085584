import { ListItem, ListItemText, Skeleton } from "@mui/material"
import * as React from "react"
import { useMemo } from "react"

interface IProps {
  loading: boolean
  rows: number
}

/**
 * Loading indicator for a list.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the loading indicator.
 */
const ListLoading: React.FunctionComponent<IProps> = (props: IProps) => {
  const { loading, rows } = props

  const rowIndex = useMemo(() => {
    return Array.from({ length: rows }, (x, i) => i)
  }, [])

  return loading ? (
    <>
      {rowIndex.map(i => (
        <ListItem key={i}>
          <ListItemText>
            <Skeleton />
          </ListItemText>
        </ListItem>
      ))}
    </>
  ) : (
    <></>
  )
}

export default ListLoading

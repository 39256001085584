import { useTheme } from "@mui/material"
import { useState } from "react"
import { useWindowResize } from "beautiful-react-hooks"

/**
 * Get the current content height. Use to fix footers to the bottom.
 *
 * @param {number} extra padding that need to be added to the calculation.
 * @returns {number} the content height.
 */
const useContentHeight = (extra: number = 0): number => {
  const theme = useTheme()
  const headerFooterHeight = Number(theme.spacing(40 + extra).replace("px", ""))
  const [contentHeight, setContentHeight] = useState(window.innerHeight - headerFooterHeight)
  useWindowResize(() => {
    setContentHeight(window.innerHeight - headerFooterHeight)
  })
  return contentHeight
}

export default useContentHeight

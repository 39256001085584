import * as React from "react"
import { styles } from "../../shared/styling/general"
import WorkflowPage from "../../shared/components/WorkflowPage"
import { Box } from "@mui/material"

/**
 * Displayed when the user doesn't have access to a page.
 *
 * @returns {React.FunctionComponent} the access denied page.
 */
const AccessDenied: React.FunctionComponent = () => {
  return (
    <WorkflowPage>
      <Box sx={styles.page}>
        <>You do not have permission to access this page.</>
      </Box>
    </WorkflowPage>
  )
}

export default AccessDenied

import { CONNECTION_ERROR, IConnectionError, setFormConnectionErrors, TSetFormError } from "../models/IConnectionError"
import { useCallback, useState } from "react"
import { useNavigate } from "@reach/router"

interface IUseApiAddResponse<T> {
  handleAdd: (t: T) => Promise<T | undefined>
  saving: boolean
  connectionError: IConnectionError | undefined
}

export interface IUseApiAddProps<T> {
  apiFunction: (item: T) => Promise<T>
  setError?: TSetFormError
}

/**
 * Hook for adding REST objects.
 *
 * @param {IUseApiAddProps} props See IProps for details.
 * @returns {IUseApiAddResponse} See IUseApiEditResponse for details.
 */
const useApiAdd = <T>(props: IUseApiAddProps<T>): IUseApiAddResponse<T> => {
  const { apiFunction, setError } = props
  const navigate = useNavigate()
  const [saving, setSaving] = useState(false)
  const [connectionError, setConnectionError] = useState<IConnectionError | undefined>()

  const handleAdd = useCallback(
    async (t: T): Promise<T | undefined> => {
      setSaving(true)
      try {
        const c1 = await apiFunction(t)
        setSaving(false)
        return c1
      } catch (reason: any) {
        if (reason?.response !== undefined) {
          setError !== undefined && setFormConnectionErrors(reason.response.data, setError)
          setConnectionError(reason.response)
        } else {
          setConnectionError(CONNECTION_ERROR)
        }
        setSaving(false)
      }
    },
    [navigate]
  )

  return {
    saving,
    handleAdd,
    connectionError,
  }
}

export default useApiAdd

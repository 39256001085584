import { PaletteColor } from "@mui/material"

export const WorkAssignmentIcon = "M 0,0 -1,-2 V -43 H 1 V -2 z M 1,-40 H 30 V -20 H 1 z"
export const PushpinIcon =
  "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
export const PersonPushpinIcon =
  "M12 2c-4.97 0-9 4.03-9 9 0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11c0-4.97-4.03-9-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"

interface IProps {
  path: string
  fillColor: string | PaletteColor
  scale?: number
  anchor?: google.maps.Point
}

/**
 * Get an icon for use on a map.
 *
 * @param {IProps} props See IProps for details.
 * @returns {any} the icon
 */
export const icon = (props: IProps): any => {
  const { path, fillColor, scale = 1.6, anchor = new google.maps.Point(12, 22) } = props
  return {
    path,
    fillColor: typeof fillColor === "string" ? fillColor : fillColor.main,
    fillOpacity: 1.0,
    strokeWeight: 1.0,
    strokeColor: typeof fillColor === "string" ? "#444" : fillColor.dark,
    strokeOpacity: 1,
    rotation: 0,
    scale,
    anchor,
  }
}

import moment from "moment"
import { DATE_TIME_FORMAT } from "../../config/config"

/**
 * Truncate a string to a specific length and add dots to the end. Also removes special characters.
 *
 * @param {string | undefined | null} str string to truncate.
 * @param {number} num length to truncate to.
 * @returns {string | undefined | null} the truncated string
 */
export const truncateString = (str: string | undefined | null, num: number = 30): string | undefined | null => {
  if (str === null || str === undefined || str.length <= num) {
    return str
  }
  return str.slice(0, num).replace(/<\/?[^>]+(>|$)/g, "") + "..."
}

/**
 * Check to see if a string is going to be truncated.
 *
 * @param {string} str to check.
 * @param {number} num length to truncate to.
 * @returns {boolean} true if the value is truncated.
 */
export const isTruncated = (str: string | undefined | null, num: number = 30): boolean => {
  return str !== null && str !== undefined ? str.length > num : false
}


/**
 * Convert an iso date string to a moment or null
 *
 * @param {string | null} value string or null.
 * @returns {moment.Moment | null} the moment or null.
 */
export const isoAsDate = (value: string | null): moment.Moment | null => {
  if (value === null || value === "") {
    return null
  }
  return moment(value)
}

/**
 * Takes a date-time string and removes the timezone from it.
 *
 * @param {string | null} dateTimeStr A date-time string that includes a timezone.
 * @returns {string | null} The date-time string with the timezone removed.
 */
export const removeTimezone = (dateTimeStr: string | null): string | null => {
  if (dateTimeStr === null) {
    return dateTimeStr
  }
  if (dateTimeStr.endsWith("Z")) {
    // todo: this is ugly. must fix on the server.
    const dateTimeMoment = moment(dateTimeStr.substring(0, dateTimeStr.length - 1))
    return dateTimeMoment.subtract(5, "hour").format(DATE_TIME_FORMAT)
  }
  return dateTimeStr.substring(0, dateTimeStr.length - 6)
}
import { Badge, Box, Grid, IconButton, Link, Paper, Popover, Typography } from "@mui/material"
import * as React from "react"
import { MouseEvent, useCallback, useState } from "react"
import { getContactPhone, IContact } from "../models/IContact"
import { InfoOutlined } from "@mui/icons-material"

interface IProps {
  contact: IContact
  prefixLabel?: string
}

/**
 * Displays a contact and allows various actions for the contact.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the contact card component.
 */
const ContactCard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { contact, prefixLabel = "" } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => setAnchorEl(null), [])
  const open = Boolean(anchorEl)

  return (
    <>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        <Box sx={{ m: 1, p: 1 }}>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            {contact.job_title}
          </Typography>
          <Typography variant="body2">
            <Link href={`mailto:${contact.first_name} ${contact.last_name}<${contact.email}>`}>{contact.email}</Link>
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <Link href={`tel:${getContactPhone(contact)}`}>{getContactPhone(contact)}</Link>
          </Typography>
        </Box>
      </Popover>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge
          color="info"
          badgeContent={`${prefixLabel !== "" ? prefixLabel + " " : ""}Contact`}
          sx={{ display: "block", "& .MuiBadge-badge": { mt: -0.1, mr: 8 } }}
        >
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <Typography variant="h5" component="div">
                {contact.first_name} {contact.last_name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Badge>
      </Paper>
    </>
  )
}

export default ContactCard

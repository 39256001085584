import * as React from "react"
import { Button } from "@mui/material"
import { IFile } from "../models/IFile"

interface IProps {
  file: IFile
  size?: "small" | "medium" | "large"
  fullWidth?: boolean
}

/**
 * This button allows the downloading of a file.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the file download button.
 */
const FileDownloadButton: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { file, size = "small", fullWidth = false } = props

  return (
    <>
      {file.file?.filename !== undefined && (
        <Button href={file.file.read_url} variant="outlined" size={size} fullWidth={fullWidth}>
          Download
        </Button>
      )}
    </>
  )
}

export default FileDownloadButton

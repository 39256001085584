import * as React from "react"
import { useCallback, useState } from "react"
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"
import InfoRoundedIcon from "@mui/icons-material/InfoOutlined"

interface IKeyEntry {
  term: string
  definition: string
}

interface IProps {
  entries: IKeyEntry[]
}

/**
 * Displays a dialog box of dictionary values.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The dictionary dialog.
 */
const DictionaryDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { entries } = props
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const open = Boolean(anchorEl)
  return (
    <>
      <IconButton onClick={handleClick}>
        <InfoRoundedIcon />
      </IconButton>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Definitions</DialogTitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Term</TableCell>
              <TableCell>Definition</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(({ term, definition }, index) => (
              <TableRow key={index}>
                <TableCell>{term}</TableCell>
                <TableCell>{definition}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DictionaryDialog

import * as React from "react"
import { MouseEvent, useCallback, useState } from "react"
import { Badge, Box, Grid, IconButton, Link, Paper, Popover, Typography } from "@mui/material"
import IUser from "../models/IUser"
import { InfoOutlined } from "@mui/icons-material"

interface IProps {
  executive: IUser
  label?: string
}

/**
 * Displays the account engineer information.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} The account engineer details.
 */
const ExecutiveCard: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { executive, label = "Account Engineer" } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => setAnchorEl(null), [])
  const open = Boolean(anchorEl)

  return (
    <>
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        <Box sx={{ m: 1, p: 1 }}>
          <Typography variant="body2">
            <Link href={`mailto:${executive.first_name} ${executive.last_name}<${executive.email}>`}>{executive.email}</Link>
          </Typography>
        </Box>
      </Popover>
      <Paper variant="outlined" sx={{ p: 1 }}>
        <Badge color="info" badgeContent={label} sx={{ display: "block", "& .MuiBadge-badge": { mt: -0.1, mr: 8 } }}>
          <Grid container alignItems="center" spacing={1} sx={{ pt: 0.5 }}>
            <Grid item xs>
              <Typography variant="h5" component="div">
                {executive.first_name} {executive.last_name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton color="primary" size="small" component="span" onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Badge>
      </Paper>
    </>
  )
}

export default ExecutiveCard

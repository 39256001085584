import { Alert, Grid } from "@mui/material"
import * as React from "react"
import { IConnectionError } from "../models/IConnectionError"
import TruncateText from "./TruncateText"

interface IProps {
  error: IConnectionError | undefined | null
}

/**
 * Displays the error message returned from a connection request.
 *
 * @param {IProps} props see IProps for details.
 * @returns {React.FC<IProps>} the component.
 */
const ErrorMessage: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { error } = props

  return error?.data !== undefined ? (
    <Grid container spacing={2} sx={{ mb: 2 }}>
      <Grid item xs>
        <Alert severity="error">
          <TruncateText text={"Issues: " + Object.values(error.data).join(", ")} />
        </Alert>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default ErrorMessage

import * as React from "react"
import moment from "moment"
import FormatDate from "../../../shared/components/format/FormatDate"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DATE_INPUT_FORMAT, DATE_OUTPUT_FORMAT } from "../../../config/config"
import { useCallback, useState } from "react"
import { IWorkAssignment, WORK_ASSIGNMENT_ENDPOINT } from "../../../shared/models/IWorkAssignment"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ViewLoading from "../../../shared/components/ViewLoading"
import { IPaging } from "../../../shared/models/IPaging"
import ErrorMessage from "../../../shared/components/ErrorMessage"

const waRepository = new RestRepository<IWorkAssignment>(WORK_ASSIGNMENT_ENDPOINT)

interface IProps {
  waId: number
  field: string
  title: string
  onChange: (wa: IWorkAssignment) => void
  value: string | moment.Moment | null
  allowClear?: boolean
}

/**
 * Generic component for changing a date.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the date change component.
 */
const ChangeDate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field, title, value, waId, onChange, allowClear = false } = props

  const [open, setOpen] = useState(false)
  const [date, setDate] = useState(moment())

  const [saving, setSaving] = useState(false)
  const [savingError, setSavingError] = useState<IConnectionError | null>(null)

  const handleDateChange = useCallback((e: moment.Moment | null) => {
    setDate(moment(e))
  }, [])

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleSave = useCallback(async () => {
    setSaving(true)
    setSavingError(null)
    try {
      const paging: IPaging = { filters: [{ field: "date", value: date.format(DATE_OUTPUT_FORMAT) }] }
      await waRepository.action(waId, field, paging)
      const wa1 = await waRepository.read(waId)
      onChange(wa1)
    } catch (reason: any) {
      if (reason.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
    setOpen(false)
  }, [date, waId])

  const handleClearDate = useCallback(async () => {
    setSaving(true)
    setSavingError(null)
    try {
      await waRepository.action(waId, `clear_${field}`)
      const wa1 = await waRepository.read(waId)
      onChange(wa1)
    } catch (reason: any) {
      if (reason.response !== undefined) {
        setSavingError(reason.response)
      } else {
        setSavingError(CONNECTION_ERROR)
      }
    }
    setSaving(false)
    setOpen(false)
  }, [waId, field])

  return (
    <>
      <Button size="small" onClick={handleOpen} fullWidth variant={value !== null ? "outlined" : "contained"}>
        <FormatDate value={value as string} nullText="Not Scheduled" />
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 1 }}>
            <ErrorMessage error={savingError} />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date"
                onChange={handleDateChange}
                value={date}
                inputFormat={DATE_INPUT_FORMAT}
                renderInput={params => <TextField fullWidth {...params} InputLabelProps={{ shrink: true }} />}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2}>
            {allowClear && (
              <Grid item>
                <Button onClick={handleClearDate}>Clear</Button>
              </Grid>
            )}
            <Grid item xs>
              <ViewLoading loading={saving} />
            </Grid>
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSave} disabled={date === undefined}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ChangeDate

import { Box } from "@mui/material"
import * as React from "react"
import { useCallback, useState } from "react"

interface IUseTabPanelResponse {
  tab: number
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void
}

/**
 * This hook handles the state of the tab panel.
 *
 * @returns {IUseTabPanelResponse} the state and event handler.
 */
export const useTabPanel = (): IUseTabPanelResponse => {
  const [tab, setTab] = useState<number>(0)

  const handleTabChange = useCallback((_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }, [])

  return { tab, handleTabChange }
}

interface IProps {
  children?: React.ReactNode
  index: number
  value: number
}

/**
 * Renders and hides the contents of the tab panel.
 *
 * @param {IProps} props See ITabPanelProps for details.
 * @returns {React.FC<IProps>} The tab panel.
 */
const TabPanel: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { children, value, index, ...other } = props

  return (
    <Box hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </Box>
  )
}

export default TabPanel

import * as React from "react"
import { IWorkAssignmentConsultant } from "../models/IWorkAssignmentConsultant"
import FormatNumber from "./format/FormatNumber"
import { Box } from "@mui/material"
import HtmlToolTip from "./HtmlToolTip"

interface IProps {
  wac: IWorkAssignmentConsultant
  showDetails?: boolean
}

/**
 * Display the consultant rate or the hourly rate.
 * todo: move to shared sdk.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the consultant rate.
 */
const ConsultantRate: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { wac, showDetails = false } = props

  return (
    <HtmlToolTip
      title={
        wac.hourly_rate && showDetails ? (
          <>
            {wac.hourly_rate && <>{wac.hours} @ </>}
            <FormatNumber value={wac.amount} display="inline" suffixUnits={wac.hourly_rate ? "/hr" : ""} twoDecimalPlaces />
          </>
        ) : (
          ""
        )
      }
    >
      <Box>
        <FormatNumber value={wac.total_amount} display="inline" twoDecimalPlaces />
      </Box>
    </HtmlToolTip>
  )
}

export default ConsultantRate

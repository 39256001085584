import * as React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import {
  EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT,
  IWorkAssignmentConsultant
} from "../../../shared/models/IWorkAssignmentConsultant"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { RestRepository } from "../../../shared/repositories/RestRepository"
import ViewLoading from "../../../shared/components/ViewLoading"
import FormatNumber from "../../../shared/components/format/FormatNumber"
import TruncateText from "../../../shared/components/TruncateText"
import ErrorMessage from "../../../shared/components/ErrorMessage"
import { CONNECTION_ERROR, IConnectionError } from "../../../shared/models/IConnectionError"
import moment from "moment"
import FormatDate from "../../../shared/components/format/FormatDate"
import { EXPENSE_REPORTS_ENDPOINT } from "../../../shared/models/IExpenseReport"
import useApiAdd, { IUseApiAddProps } from "../../../shared/hooks/useApiAdd"
import { LookupsRepository } from "../../../shared/repositories/LookupsRepository"
import ConsultantRate from "../../../shared/components/ConsultantRate"
import {
  EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT,
  EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT,
  IWorkAssignment
} from "../../../shared/models/IWorkAssignment"
import { IPaging } from "../../../shared/models/IPaging"
import { IAdminItem } from "../../../shared/models/components/IAdminItem"
import { IListItem } from "../../../shared/models/components/IListItem"
import AdminItemDialog from "./AdminItemDialog"
import { useInterval, useLocalStorage } from "beautiful-react-hooks"
import { EXPENSE_REPORTS_URL } from "../../../config/urls"
import PrivateComponent from "../../../shared/components/PrivateComponent"
import { ACCESS_CONSULTANT_ADMIN_EXPENSE_REPORT_GROUP } from "../../../config/permissions"
import useAuth from "../../../shared/hooks/useAuth"
import AlertDialog from "../../../shared/components/AlertDialog"
import { DATE_OUTPUT_FORMAT, DATE_TIME_NICE_FORMAT } from "../../../config/config"
import { removeTimezone } from "../../../shared/utilities/format_utility"

const erConsultantRepository = new RestRepository<IWorkAssignmentConsultant>(EXPENSE_REPORT_WORK_ASSIGNMENT_CONSULTANT_ENDPOINT)
const erTechReviewRepository = new RestRepository<IWorkAssignment>(EXPENSE_REPORT_WORK_ASSIGNMENT_TECH_REVIEW_ENDPOINT)
const erAdminReviewRepository = new RestRepository<IWorkAssignment>(EXPENSE_REPORT_WORK_ASSIGNMENT_ADMIN_REVIEW_ENDPOINT)

const lookupsRepository = new LookupsRepository()

const expenseReportRepository = new RestRepository<IExpenseReportAdd>(EXPENSE_REPORTS_ENDPOINT)

interface ICanExpenseReport {
  can_expense_report: boolean
  start_date: string | null
  expense_report_due_date: string | null
  next_start_date: string | null
  remaining: number | null
  tz: string | null
}

interface IExpenseReportAdd {
  notes: string
  expense_report_admin_items: IAdminItem[] | null | undefined
}

interface IProps {
  onChange: () => void
}

/**
 * Create a new expense report if there are any work assignments available.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the create dialog.
 */
const AddExpenseReportDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { onChange } = props
  const [openConfirm, setOpenConfirm] = useState(false)
  const [open, setOpen] = useState(false)
  const [adminItemDialogOpen, setAdminItemDialogOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [initial, setInitial] = useState<boolean>(true)
  const [error, setError] = useState<IConnectionError | null>(null)
  const [workAssignments, setWorkAssignments] = useState<IWorkAssignmentConsultant[] | null>(null)
  const [techReviews, setTechReviews] = useState<IWorkAssignment[] | null>(null)
  const [techReviews2, setTechReviews2] = useState<IWorkAssignment[] | null>(null)
  const [adminReviews, setAdminReviews] = useState<IWorkAssignment[] | null>(null)
  const [notes, setNotes] = useState<string>("")
  const [canExpenseReport, setCanExpenseReport] = useState<ICanExpenseReport | null>(null)
  const [adminItems, setAdminItems] = useLocalStorage<IAdminItem[]>(`${EXPENSE_REPORTS_URL}_ADD`, [])
  const [timePassed, setTimePassed] = useState<number>(0)

  const [currentAdminItem, setCurrentAdminItem] = useState<IAdminItem | null>(null)
  const [currentAdminItemIndex, setCurrentAdminItemIndex] = useState<number | null>(null)

  const { currentUser, isUserInGroups } = useAuth()

  const canAdminTime = useMemo(() => {
    return isUserInGroups?.([ACCESS_CONSULTANT_ADMIN_EXPENSE_REPORT_GROUP], currentUser) === true
  }, [])

  const addProps: IUseApiAddProps<IExpenseReportAdd> = {
    apiFunction: expenseReportRepository.add
  }
  const { saving, handleAdd, connectionError } = useApiAdd<IExpenseReportAdd>(addProps)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleAdminItemDialogOpen = useCallback(() => setAdminItemDialogOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  const loadWorkAssignments = useCallback(async () => {
    try {
      const workAssignments1 = await erConsultantRepository.findAllNoPaging()
      setWorkAssignments(workAssignments1)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response.data)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
  }, [])

  const loadTechReviews = useCallback(async () => {
    try {
      const paging: IPaging = {
        filters: [{ field: "is_tech_reviewer", value: 1 }]
      }
      const techReviews1 = await erTechReviewRepository.findAllNoPaging(paging)
      setTechReviews(techReviews1)
      const paging2: IPaging = {
        filters: [{ field: "is_tech_reviewer", value: 2 }]
      }
      const techReviews2 = await erTechReviewRepository.findAllNoPaging(paging2)
      setTechReviews2(techReviews2)
    } catch (reason: any) {
    }
  }, [])

  const loadAdminReviews = useCallback(async () => {
    try {
      const adminReviews1 = await erAdminReviewRepository.findAllNoPaging()
      setAdminReviews(adminReviews1)
    } catch (reason: any) {
    }
  }, [])

  const loadCanExpenseReportThisPayPeriod = useCallback(async () => {
    setLoading(true)
    try {
      const canExpenseReport = await lookupsRepository.lookup<ICanExpenseReport>("can_expense_report_this_pay_period")
      setCanExpenseReport(canExpenseReport)
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response.data)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
    setLoading(false)
  }, [])

  const handleAdminItemAdd = useCallback(
    (adminItem1: IAdminItem | null) => {
      if (adminItem1 !== null) {
        if (currentAdminItem === null) {
          setAdminItems([...adminItems, adminItem1])
        } else {
          const adminItems1 = adminItems.map((item, index) => {
            if (index === currentAdminItemIndex) {
              return { ...adminItem1 }
            }
            return item
          })
          setAdminItems(adminItems1)
        }
      }
      setCurrentAdminItem(null)
      setAdminItemDialogOpen(false)
    },
    [adminItems, currentAdminItem, currentAdminItemIndex]
  )

  const handleAdminItemRemove = useCallback(
    (index1: number) => () => {
      const adminItems1 = [...adminItems.filter((_, index) => index !== index1)]
      setAdminItems(adminItems1)
    },
    [adminItems]
  )

  const handleAdminItemEdit = useCallback(
    (index: number, adminItem1: IAdminItem) => () => {
      setCurrentAdminItem(adminItem1)
      setAdminItemDialogOpen(true)
      setCurrentAdminItemIndex(index)
    },
    [adminItems]
  )

  const handleSave = useCallback(async () => {
    setError(null)
    setOpenConfirm(false)
    try {
      const erAdminItems: IAdminItem[] = adminItems?.map(item => {
        return {
          customer: Number((item.customer as IListItem)?.id),
          account: Number((item.account as IListItem)?.id),
          expense_report_category: Number((item.expense_report_category as IListItem)?.id),
          description: item.description,
          on_date: item.on_date !== undefined && item.on_date !== null ? moment(item.on_date).format(DATE_OUTPUT_FORMAT) : null,
          hours: item.hours
        }
      })
      const newExpenseReport: IExpenseReportAdd = { notes, expense_report_admin_items: erAdminItems }
      const expenseReport1 = await handleAdd(newExpenseReport)
      if (expenseReport1 !== undefined) {
        setAdminItems([])
        setLoading(true)
        await loadCanExpenseReportThisPayPeriod()
        await loadWorkAssignments()
        await loadTechReviews()
        await loadAdminReviews()
        setLoading(false)
        onChange()
      }
      handleClose()
    } catch (reason: any) {
      if (reason?.response !== undefined) {
        setError(reason.response.data)
      } else {
        setError(CONNECTION_ERROR)
      }
    }
  }, [notes, adminItems])

  useEffect(() => {
    if (initial) {
      setInitial(false)
      void (async () => {
        setLoading(true)
        await loadCanExpenseReportThisPayPeriod()
        await loadWorkAssignments()
        await loadTechReviews()
        await loadAdminReviews()
        setLoading(false)
      })()
    }
  }, [initial])

  const totalWorkAssignment = useMemo(() => {
    if (workAssignments !== null) {
      return workAssignments.map(wac => wac.total_amount).reduce((acc, val) => acc + val, 0)
    }
    return 0
  }, [workAssignments])

  const totalTechReview1Hours = useMemo(() => {
    if (techReviews !== null) {
      return techReviews.map(techReview => techReview.tech_review_hours).reduce((acc, val) => acc + val, 0)
    }
    return 0
  }, [techReviews])

  const totalTechReview2Hours = useMemo(() => {
    if (techReviews2 !== null) {
      return techReviews2.map(techReview => techReview.tech_review_2_hours).reduce((acc, val) => acc + val, 0)
    }
    return 0
  }, [techReviews2])

  const totalAdminReviewHours = useMemo(() => {
    if (adminReviews !== null) {
      return adminReviews.map(adminReview => adminReview.admin_review_hours).reduce((acc, val) => acc + val, 0)
    }
    return 0
  }, [adminReviews])

  const totalAdminTime = useMemo(() => {
    return adminItems.map(adminItem => (adminItem.hours === null ? 0 : adminItem.hours)).reduce((acc, val) => acc + val, 0)
  }, [adminItems, totalAdminReviewHours])

  const totalAmount = totalWorkAssignment
  const totalHours = totalTechReview1Hours + totalTechReview2Hours + totalAdminReviewHours + totalAdminTime

  const hasAssignments = useMemo(() => {
    if (workAssignments !== null && workAssignments.length > 0) {
      return true
    }
    if (techReviews !== null && techReviews.length > 0) {
      return true
    }
    return techReviews2 !== null && techReviews2.length > 0
  }, [workAssignments, techReviews, techReviews2])

  const timeRemaining = useMemo(() => {
    if (canExpenseReport !== null) {
      return moment.duration(canExpenseReport.remaining, "seconds").subtract(timePassed, "milliseconds")
    }
    return null
  }, [canExpenseReport, timePassed])

  const timeRemainingFormatted = useMemo(() => {
    if (timeRemaining !== null) {
      if (timeRemaining.days() > 0) {
        return `${timeRemaining.days()} days ${timeRemaining.hours()} hrs ${timeRemaining.minutes()} mins ${timeRemaining.seconds()} secs`
      }
      if (timeRemaining.hours() > 0) {
        return `${timeRemaining.hours()} hrs ${timeRemaining.minutes()} mins ${timeRemaining.seconds()} secs`
      }
      return `${timeRemaining.minutes()} mins ${timeRemaining.seconds()} secs`
    }
    return ""
  }, [timeRemaining])

  useInterval(() => {
    setTimePassed(timePassed => timePassed + 1000)
  }, 1000)

  return (
    <Box sx={{ p: 2 }}>
      <ErrorMessage error={error} />
      <ErrorMessage error={connectionError} />
      {loading ? (
        <ViewLoading loading={loading} />
      ) : (
        <>
          {canExpenseReport?.can_expense_report === true && timeRemaining !== null && timeRemaining.asSeconds() > 0 ? (
            <>
              <Grid container spacing={1} alignItems="start">
                <Grid item xs={12}>
                  <Alert
                    severity={hasAssignments || canAdminTime ? "success" : "warning"}
                    action={
                      hasAssignments ? (
                        <Button startIcon={<AddIcon />} onClick={handleOpen}>
                          Create Expense Report
                        </Button>
                      ) : (
                        <PrivateComponent
                          groupNames={[ACCESS_CONSULTANT_ADMIN_EXPENSE_REPORT_GROUP]}
                          component={
                            <Button startIcon={<AddIcon />} onClick={handleOpen}>
                              Create Expense Report
                            </Button>
                          }
                        />
                      )
                    }
                  >
                    {hasAssignments ? (
                      <>
                        <Box>The follow items are available for an expense report.</Box>
                        <List dense>
                          {workAssignments !== null && workAssignments.length > 0 && (
                            <ListItem>There are {workAssignments.length} work assignments available.</ListItem>
                          )}
                          {adminReviews !== null && adminReviews.length > 0 && (
                            <ListItem>There are {adminReviews.length} admin reviews available.</ListItem>
                          )}
                          {techReviews !== null && techReviews.length > 0 && (
                            <ListItem>There are {techReviews.length} tech reviews available.</ListItem>
                          )}
                          {techReviews2 !== null && techReviews2.length > 0 && (
                            <ListItem>There are {techReviews2.length} second tech reviews available.</ListItem>
                          )}
                          {canAdminTime && <ListItem>You are eligible for admin time entry.</ListItem>}
                        </List>
                      </>
                    ) : (
                      <PrivateComponent
                        groupNames={[ACCESS_CONSULTANT_ADMIN_EXPENSE_REPORT_GROUP]}
                        component={<>No assignments were available for expense reporting. However, you can submit for
                          admin time.</>}
                        componentElse={<>No assignments were available for expense reporting.</>}
                      />
                    )}
                  </Alert>
                </Grid>
                {(hasAssignments || canAdminTime) && (
                  <>
                    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth fullScreen>
                      <AppBar sx={{ position: "relative", p: 1 }}>
                        <Grid container sx={{ width: "100%" }} alignItems="center">
                          <Grid item xs>
                            <Typography sx={{ ml: 2 }} variant="h5" component="div">
                              Create Expense Report
                            </Typography>
                          </Grid>
                          <Grid item xs sx={{ textAlign: "center" }}>
                            <Typography variant="h6" component="div">
                              Pay Period Ending
                              <Box component="span" sx={{ pl: 1 }}>
                                <FormatDate value={removeTimezone(canExpenseReport.expense_report_due_date)}
                                            format={DATE_TIME_NICE_FORMAT} />
                              </Box>
                              <Box component="span" sx={{ pl: 1 }}>
                                {canExpenseReport.tz}
                              </Box>
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Grid container alignItems="center">
                              <Grid item xs />
                              {(totalAmount > 0 || totalHours > 0) &&
                                <Grid item><Typography variant="h5">Totals</Typography></Grid>}
                              {totalAmount > 0 && (
                                <Grid item>
                                  <Typography variant="h4" sx={{ ml: 2 }}>
                                    <FormatNumber value={totalAmount} twoDecimalPlaces />
                                  </Typography>
                                </Grid>
                              )}
                              {totalHours > 0 && (
                                <Grid item>
                                  <Typography variant="h4" sx={{ ml: 2 }}>
                                    <FormatNumber value={totalHours} prefixUnits={false} twoDecimalPlaces
                                                  suffixUnits="hr" />
                                  </Typography>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </AppBar>
                      <DialogContent>
                        {workAssignments !== null && workAssignments.length > 0 && (
                          <>
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Typography variant="h5">Work Assignments</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="h6">({workAssignments.length})</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">
                                      <FormatNumber value={totalWorkAssignment} twoDecimalPlaces />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>Submitted</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Amount</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {workAssignments.map(wac => {
                                        const workAssignment = wac.work_assignment
                                        return (
                                          <TableRow key={wac.id}>
                                            <TableCell>{workAssignment.identifier}</TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.customer?.name}
                                                            placement="top-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.account?.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.location.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>
                                              <FormatDate
                                                value={workAssignment.progress_consultant_reports_submitted} />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <ConsultantRate wac={wac} showDetails />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        )}
                        {adminReviews !== null && adminReviews.length > 0 && (
                          <>
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="end" spacing={2}>
                                  <Grid item>
                                    <Typography variant="h5">Admin Review</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="h6">({adminReviews.length})</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">
                                      <FormatNumber value={totalAdminReviewHours} prefixUnits={false} twoDecimalPlaces
                                                    suffixUnits="hr" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {adminReviews.map(workAssignment => {
                                        return (
                                          <TableRow key={workAssignment.id}>
                                            <TableCell>{workAssignment.identifier}</TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.customer?.name}
                                                            placement="top-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.account?.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.location.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <FormatNumber
                                                value={workAssignment.admin_review_hours}
                                                prefixUnits={false}
                                                twoDecimalPlaces
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        )}
                        {techReviews !== null && techReviews.length > 0 && (
                          <>
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="end" spacing={2}>
                                  <Grid item>
                                    <Typography variant="h5">Tech Review 1</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="h6">({techReviews.length})</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">
                                      <FormatNumber value={totalTechReview1Hours} prefixUnits={false} twoDecimalPlaces
                                                    suffixUnits="hr" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>Submitted</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {techReviews?.map(workAssignment => {
                                        return (
                                          <TableRow key={workAssignment.id}>
                                            <TableCell>{workAssignment.identifier}</TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.customer?.name}
                                                            placement="top-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.account?.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.location.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "center" }}>
                                              <FormatDate value={workAssignment.tech_review_sign_off} />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <FormatNumber value={workAssignment.tech_review_hours} prefixUnits={false}
                                                            twoDecimalPlaces />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        )}
                        {techReviews2 !== null && techReviews2.length > 0 && (
                          <>
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="end" spacing={2}>
                                  <Grid item>
                                    <Typography variant="h5">Tech Review 2</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="h6">({techReviews2.length})</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">
                                      <FormatNumber value={totalTechReview2Hours} prefixUnits={false} twoDecimalPlaces
                                                    suffixUnits="hr" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell sx={{ textAlign: "center" }}>Submitted</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {techReviews2.map(workAssignment => {
                                        return (
                                          <TableRow key={workAssignment.id}>
                                            <TableCell>{workAssignment.identifier}</TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.customer?.name}
                                                            placement="top-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.account?.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={workAssignment.location.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <FormatDate value={workAssignment.tech_review_2_sign_off} />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <FormatNumber
                                                value={workAssignment.tech_review_2_hours}
                                                prefixUnits={false}
                                                twoDecimalPlaces
                                              />
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          </>
                        )}

                        <PrivateComponent
                          groupNames={[ACCESS_CONSULTANT_ADMIN_EXPENSE_REPORT_GROUP]}
                          component={
                            <Accordion defaultExpanded>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Grid container alignItems="end" spacing={2}>
                                  <Grid item>
                                    <Typography variant="h5">Admin Time</Typography>
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="h6">({adminItems.length})</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography variant="h6">
                                      <FormatNumber value={totalAdminTime} prefixUnits={false} twoDecimalPlaces
                                                    suffixUnits="hr" />
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container>
                                  <Grid item xs />
                                  <Grid item>
                                    <Button onClick={handleAdminItemDialogOpen}>Add Admin Time</Button>
                                    <AdminItemDialog
                                      open={adminItemDialogOpen}
                                      onChange={handleAdminItemAdd}
                                      editAdminItem={currentAdminItem}
                                    />
                                  </Grid>
                                </Grid>
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Customer</TableCell>
                                        <TableCell>Account</TableCell>
                                        <TableCell>Category</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell sx={{ textAlign: "right" }}>Hours</TableCell>
                                        <TableCell />
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {adminItems.map((adminItem, index) => {
                                        return (
                                          <TableRow key={index}>
                                            <TableCell>
                                              <TruncateText text={(adminItem.customer as IListItem)?.name}
                                                            placement="top-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={(adminItem.account as IListItem)?.name}
                                                            placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText
                                                text={(adminItem.expense_report_category as IListItem)?.name}
                                                placement="left-start"
                                              />
                                            </TableCell>
                                            <TableCell>
                                              <TruncateText text={adminItem.description} placement="left-start" />
                                            </TableCell>
                                            <TableCell>
                                              {adminItem.on_date !== undefined ?
                                                <FormatDate value={adminItem.on_date} /> : "Not Set"}
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <FormatNumber value={adminItem.hours} prefixUnits={false}
                                                            twoDecimalPlaces />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: "right" }}>
                                              <Button onClick={handleAdminItemEdit(index, adminItem)}>Edit</Button>
                                              <Button onClick={handleAdminItemRemove(index)}>Delete</Button>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      })}
                                    </TableBody>
                                  </Table>
                                  {adminItems.length === 0 && (
                                    <Box sx={{ pt: 2, pb: 2 }}>
                                      <Alert color="info">No admin time has been added.</Alert>
                                    </Box>
                                  )}
                                </TableContainer>
                              </AccordionDetails>
                            </Accordion>
                          }
                        />
                      </DialogContent>
                      <DialogActions>
                        <Grid container sx={{ pl: 2, pr: 2, pb: 2, borderTop: "1px solid #bbb" }}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Box sx={{ pt: 2, pb: 2 }}>
                                  <TextField
                                    label="Comment"
                                    helperText="Add a comment to identify any discrepancies with your expense report."
                                    value={notes}
                                    fullWidth
                                    multiline
                                    onChange={e => setNotes(e.target.value)}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} alignItems="end">
                              <Grid item>
                                <Button onClick={handleClose}>Close</Button>
                              </Grid>
                              <Grid item xs>
                                <ViewLoading loading={saving} />
                              </Grid>
                              <Grid item>
                                <AlertDialog
                                  open={openConfirm}
                                  onNo={() => setOpenConfirm(false)}
                                  onYes={handleSave}
                                  message={
                                    <>
                                      <Box>
                                        Are you sure you want to submit your expense report for pay period ending on
                                        <Box component="span" sx={{ pl: 1 }}>
                                          <FormatDate value={removeTimezone(canExpenseReport.expense_report_due_date)}
                                                      format={DATE_TIME_NICE_FORMAT} />
                                        </Box>
                                        <Box component="span" sx={{ pl: 1 }}>
                                          {canExpenseReport.tz}
                                        </Box>
                                        , with the following items? You can only submit one expense report per pay
                                        period.
                                      </Box>
                                      <Typography variant="h6">Items</Typography>
                                      <List dense>
                                        {workAssignments !== null && workAssignments.length > 0 && (
                                          <ListItem>{workAssignments.length} work assignments.</ListItem>
                                        )}
                                        {adminReviews !== null && adminReviews.length > 0 && (
                                          <ListItem>{adminReviews.length} admin reviews.</ListItem>
                                        )}
                                        {techReviews !== null && techReviews.length > 0 && (
                                          <ListItem>{techReviews.length} tech reviews.</ListItem>
                                        )}
                                        {techReviews2 !== null && techReviews2.length > 0 && (
                                          <ListItem>{techReviews2.length} second tech reviews.</ListItem>
                                        )}
                                        {canAdminTime && adminItems.length > 0 &&
                                          <ListItem>{adminItems.length} admin entries.</ListItem>}
                                      </List>
                                      <Typography variant="h6">Comment</Typography>
                                      <p>{notes === "" ? "No comment" : notes}</p>
                                      <Typography variant="h6">Amounts</Typography>
                                      <Grid container>
                                        {totalAmount > 0 && (
                                          <>
                                            <Grid item xs>
                                              Total Amount
                                            </Grid>
                                            <Grid item>
                                              <FormatNumber value={totalAmount} twoDecimalPlaces />
                                            </Grid>
                                          </>
                                        )}
                                        {totalHours > 0 && (
                                          <>
                                            <Grid item xs={12} />
                                            <Grid item xs>
                                              Total Hours
                                            </Grid>
                                            <Grid item>
                                              <FormatNumber value={totalHours} twoDecimalPlaces prefixUnits={false}
                                                            suffixUnits="hr" />
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    </>
                                  }
                                />
                                <Button variant="contained" color="primary" onClick={() => setOpenConfirm(true)}>
                                  Submit Expense Report
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                <Alert severity="info" sx={{ mt: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <strong>Pay period started:</strong>
                    </Grid>
                    <Grid item>
                      <Box component="span">
                        <FormatDate value={removeTimezone(canExpenseReport.start_date)}
                                    format={DATE_TIME_NICE_FORMAT} />
                      </Box>
                      <Box component="span" sx={{ pl: 1 }}>
                        {canExpenseReport.tz}
                      </Box>
                    </Grid>
                    <Grid item>
                      <strong>Expense Report Due:</strong>
                    </Grid>
                    <Grid item>
                      <Box component="span">
                        <FormatDate value={removeTimezone(canExpenseReport.expense_report_due_date)}
                                    format={DATE_TIME_NICE_FORMAT} />
                      </Box>
                      <Box component="span" sx={{ pl: 1 }}>
                        {canExpenseReport.tz}
                      </Box>
                    </Grid>
                    <Grid item>
                      <strong>Time Remaining:</strong>
                    </Grid>
                    <Grid item>
                      {timeRemainingFormatted}
                    </Grid>
                  </Grid>
                </Alert>
              </Grid>
            </>
          ) : (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs>
                <Alert severity="info">
                  {canExpenseReport !== null && timeRemaining !== null && timeRemaining.asSeconds() > 0 ? (
                    <>
                      {canExpenseReport.start_date === null && <>Expense reports are currently unavailable.</>}
                      {canExpenseReport.next_start_date !== null && (
                        <>
                          {canExpenseReport.start_date === null ? "They" : "Expense reports"} will open again at{" "}
                          <FormatDate value={canExpenseReport.next_start_date} format={DATE_TIME_NICE_FORMAT} />
                          <Box component="span" sx={{ pl: 1 }}>
                            {canExpenseReport.tz}.
                          </Box>
                        </>
                      )}
                      {canExpenseReport.start_date !== null && (
                        <>Your expense report has been submitted. Click on the expense report listed below to review.</>
                      )}
                    </>
                  ) : (
                    <>Expense reports are currently unavailable.</>
                  )}
                </Alert>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  )
}

export default AddExpenseReportDialog

import { IOrdering } from "../models/IOrdering"
import { Box, TableSortLabel } from "@mui/material"
import { visuallyHidden } from "@mui/utils"
import * as React from "react"
import { useCallback } from "react"

interface IProps {
  field: string
  title: string | React.ReactElement
  ordering?: IOrdering // Current sort state.
  onOrdering?: (ordering: IOrdering) => void
}

/**
 * Adds a button for sorting a table column.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FunctionComponent<IProps>} the table sort button.
 */
const TableOrdering: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { field, title, ordering, onOrdering } = props

  const createSortHandler = useCallback(
    (ordering?: IOrdering) => (_e: React.MouseEvent<unknown>) => {
      if (onOrdering !== undefined && ordering !== undefined) {
        onOrdering(ordering)
      }
    },
    []
  )

  return onOrdering !== undefined ? (
    <TableSortLabel
      active={ordering?.field === field}
      direction={ordering?.field === field ? ordering?.direction : "asc"}
      onClick={createSortHandler({
        direction: ordering?.direction === "asc" ? `desc` : "asc",
        field,
      })}
    >
      {title}
      {ordering?.field === field ? (
        <Box component="span" sx={visuallyHidden}>
          {ordering.direction === "desc" ? "sorted descending" : "sorted ascending"}
        </Box>
      ) : null}
    </TableSortLabel>
  ) : (
    <Box>{title}</Box>
  )
}

export default TableOrdering

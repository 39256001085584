import * as React from "react"
import { Dialog, DialogTitle, IconButton, List, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material"
import SortIcon from "@mui/icons-material/Sort"
import ArrowUpward from "@mui/icons-material/ArrowUpward"
import ArrowDownward from "@mui/icons-material/ArrowDownward"
import { IOrdering } from "../../../shared/models/IOrdering"
import { useCallback } from "react"

interface IProps {
  ordering?: IOrdering
  onOrdering: (ordering: IOrdering) => void
}

const orderings: IOrdering[] = [
  { field: "customer__name", title: "Customer" },
  { field: "account__name", title: "Account" },
  { field: "location__name", title: "Location" },
  { field: "inspection_type", title: "Inspection Type" },
  { field: "visit_date", title: "Visit Date" },
  { field: "consultant_due_date", title: "Due Date" },
]

/**
 * Sorting dialog mobile browsers.
 *
 * @param {IProps} props See IProps for details.
 * @returns {React.FC<IProps>} the dialog.
 */
const SortDialog: React.FC<IProps> = (props: IProps): React.ReactElement => {
  const { ordering, onOrdering } = props
  const [open, setOpen] = React.useState(false)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOrder = useCallback(
    (order: IOrdering) => () => {
      const newOrder: IOrdering = { direction: ordering?.direction === "asc" ? `desc` : "asc", field: order.field }
      onOrdering(newOrder)
    },
    [ordering]
  )

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <SortIcon />
      </IconButton>

      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Sort Work Assignments</DialogTitle>
        <List sx={{ pt: 0 }}>
          {orderings.map(order => (
            <ListItemButton onClick={handleOrder(order)} selected={order.field === ordering?.field} key={order.field}>
              <ListItemText primary={order.title} />
              {order.field === ordering?.field && (
                <ListItemSecondaryAction>{ordering?.direction === "asc" ? <ArrowUpward /> : <ArrowDownward />}</ListItemSecondaryAction>
              )}
            </ListItemButton>
          ))}
        </List>
      </Dialog>
    </>
  )
}

export default SortDialog
